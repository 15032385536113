import { useIonToast } from "@ionic/react";
import { useCallback } from "react";

// Default config for displaying an error

function useToastError() {
  const [present] = useIonToast();

  const showError = useCallback(
    (error: {message: string}) =>
      present({
        message: error.message,
        duration: 15000,
        color: "danger",
        position: "top",
        buttons: [
          {
            text: "Done",
            role: "cancel",
          },
        ],
      }),
    [present]
  );

  return showError;
}

export default useToastError;
