import { IonAlert, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonNote, IonSpinner, IonTitle, IonToolbar, useIonToast } from "@ionic/react";
import { differenceInCalendarDays, differenceInDays, format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { chatbubble, close, copy, map } from "ionicons/icons";
import { getFunctions, httpsCallable } from "firebase/functions";


import "./StayCard.css";
import EditStay from "./EditStay";
import { Member } from "../models/member.model";
interface StayCardProps {
    parentPage: HTMLElement | null;
    reservation: any;
    member: Member;
}

const StayCard = ({ reservation, parentPage, member }: StayCardProps) => {
    const [present] = useIonToast();
    const detailModal = useRef<HTMLIonModalElement>(null);
    const daysUntil = differenceInDays(new Date(reservation.START_DATE), new Date())
    const [siteDetails, setSiteDetails] = useState<any>();
    const [stayDetailsOpen, setStayDetailsOpen] = useState(false);
    const [cancelling, setCancelling] = useState(false);
    const [numberOfNights, setNumberOfNights] = useState(0);
    const [loadingSite, setLoadingSite] = useState(false);
    // const [canEdit, setCanEdit] = useState(false);
    const [madeWithin72Hours, stayMadeWithin72Hours] = useState(false);
    const [startsMoreThan72HoursAway, stayStartsMoreThan72HoursAway] = useState(false);
    const [editMode, setEditMode] = useState(false);

    const cancelStay = () => {
        setCancelling(true);
        const functions = getFunctions();
        httpsCallable(functions, 'planyo-cancelReservation')({ reservationId: reservation.RESERVATION_ID, numberOfNights })
            .then((result: any) => {
                if (result.data.status > 16 && result.data.status < 64) {
                    detailModal.current?.dismiss();
                    present({
                        message: 'Successfully cancelled your stay',
                        duration: 5000,
                        color: "primary",
                        position: "top",
                        buttons: [
                            {
                                text: "Done",
                                role: "cancel",
                            },
                        ],
                    })
                } else {
                    present({
                        message: 'Error cancelling your stay',
                        duration: 5000,
                        color: "danger",
                        position: "top",
                        buttons: [
                            {
                                text: "Done",
                                role: "cancel",
                            },
                        ],
                    })
                }
            })
            .catch((error) => {
                console.error('Cancel Stay Error: ', error)
            })
            .finally(() => {
                setCancelling(false);
            })

    }

    const editStay = () => {
        console.log('edit stay: ', reservation)
        setEditMode(true);
    }

    const openGoogleMaps = () => {
        if (siteDetails.properties.gps_coords) {
            window.open(`https://www.google.com/maps?saddr=&daddr=${siteDetails.properties.gps_coords}`, '_blank');
        } else {
            present({
                message: 'Oops, this site is missing GPS coordinates. Please copy the address into your maps app.',
                duration: 5000,
                color: "danger",
                position: "top",
                buttons: [
                    {
                        text: "Done",
                        role: "cancel",
                    },
                ],
            })
        }
    };

    const messageLiason = () => {
        alert('Coming Soon')
    }

    const copyAddress = () => {
        navigator.clipboard.writeText(siteDetails.properties.address);
        present({
            message: 'Copied Address to Clipboard',
            duration: 5000,
            color: "primary",
            position: "top",
            buttons: [
                {
                    text: "Done",
                    role: "cancel",
                },
            ],
        })
    }

    const fetchSiteDetails = () => {
        // fetch the site details
        setLoadingSite(true);
        const functions = getFunctions();
        httpsCallable(functions, 'planyo-getResource')({
            resourceId: reservation.RESOURCE_ID
        })
            .then((result: any) => {
                setSiteDetails(result.data)
            })
            .finally(() => { setLoadingSite(false); })
    }

    useEffect(() => {
        if (reservation.START_DATE && reservation.END_DATE) {
            const start = new Date(reservation.START_DATE);
            const end = new Date(reservation.END_DATE);
            const n = differenceInCalendarDays(end, start)
            setNumberOfNights(n)
        }

        console.log('reservation: ', reservation)

        if (reservation.CREATED) {
            let created;
            if (reservation.CREATED.hasOwnProperty('seconds')) {
                created = new Date(reservation.CREATED.seconds * 1000);
            } else {
                created = new Date(reservation.CREATED);
            }
            console.warn('Created at: ', created)
            const now = new Date();
            const diff = differenceInCalendarDays(now, created)
            if (diff < 3) {
                console.log('stay made within 72 hours')
                stayMadeWithin72Hours(true);
            }

            const start = new Date(reservation.START_DATE);
            const startDiff = differenceInCalendarDays(start, now)
            if (startDiff > 3) {
                console.log('stay starts more than 72 hours away: ', startDiff, start)
                stayStartsMoreThan72HoursAway(true);
            }
        }

        if (stayDetailsOpen && reservation.RESOURCE_ID) {
            fetchSiteDetails();
        } else {
            setSiteDetails(undefined)
        }

    }, [stayDetailsOpen, reservation]) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {

    }, [])

    return (
        <>
            <IonCard>
                <IonCardHeader style={{ borderBottom: '2px solid #000' }}>
                    <IonCardTitle>
                        {reservation.RESOURCE_NAME}
                        {reservation.isCancelled && (
                            <IonChip className="status-chip" color="danger">
                                <IonLabel>Cancelled</IonLabel>
                            </IonChip>
                        )}
                        {parseInt(reservation.status) <= 7 && (
                            <IonChip className="status-chip" color="success">
                                <IonLabel>Confirmed</IonLabel>
                            </IonChip>
                        )}
                    </IonCardTitle>


                    {!reservation.isPast && !reservation.isCancelled && (
                        <>
                            {daysUntil > 0 ? (
                                <IonCardSubtitle>{daysUntil} days until your stay</IonCardSubtitle>
                            ) : (
                                <IonCardSubtitle>Current Stay</IonCardSubtitle>
                            )}
                        </>
                    )}

                </IonCardHeader>
                <IonCardContent>
                    <div className="reservation-dates">{format(new Date(reservation.START_DATE), 'MMM dd')} - {format(new Date(reservation.END_DATE), 'MMM dd, yyyy')}</div>
                    <IonButton
                        onClick={() => setStayDetailsOpen(true)}
                        expand="block"
                        color="primary"
                        shape="round"
                        style={{ marginTop: '20px' }}>
                        View Details

                        <IonModal
                            isOpen={stayDetailsOpen}
                            ref={detailModal}
                            presentingElement={parentPage!}
                            onDidDismiss={() => setStayDetailsOpen(false)}
                        >

                            <IonHeader>
                                <IonToolbar>
                                    <IonButtons slot="start">
                                        <IonButton onClick={() => detailModal.current?.dismiss()}>
                                            <IonIcon icon={close} />
                                        </IonButton>
                                    </IonButtons>
                                    <IonTitle>Stay Details</IonTitle>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent>

                                <IonItem lines="none">
                                    <IonLabel>Start Date</IonLabel>
                                    <IonNote>{format(new Date(reservation.START_DATE), 'MMM dd, yyyy')}</IonNote>
                                </IonItem>
                                <IonItem lines="none">
                                    <IonLabel>End Date</IonLabel>
                                    <IonNote>{format(new Date(reservation.END_DATE), 'MMM dd, yyyy')}</IonNote>
                                </IonItem>
                                <IonItem onClick={messageLiason}>
                                    <IonLabel>Onsite Contact</IonLabel>
                                    <IonNote></IonNote>
                                    <IonIcon slot="end" icon={chatbubble}></IonIcon>
                                </IonItem>

                                <IonItem onClick={copyAddress}>
                                    <IonLabel>
                                        <div>Address</div>
                                        <IonNote>{siteDetails?.properties.address}</IonNote>
                                    </IonLabel>
                                    {loadingSite ? <IonSpinner slot="end" /> : <IonIcon slot="end" icon={copy}></IonIcon>}

                                </IonItem>

                                <IonButton className="ion-margin" expand="block" shape="round" onClick={openGoogleMaps}>
                                    <IonLabel>Get Directions</IonLabel>
                                    {loadingSite ? <IonSpinner slot="end" /> : <IonIcon slot="end" icon={map}></IonIcon>}

                                </IonButton>

                                {reservation.isFuture && (
                                    <div className="ion-padding" style={{ marginTop: '20px' }}>

                                        {editMode && (
                                            <EditStay
                                                stay={reservation}
                                                setEditMode={setEditMode}
                                                showModal={setStayDetailsOpen}
                                                member={member} />
                                        )}

                                        {/* Allow the stay to be changed whenever if its a pass OR within the time constraints */}
                                        {reservation.MEMBERSHIP_TYPE === 'pass' || (madeWithin72Hours && startsMoreThan72HoursAway)
                                            ? (
                                                <>
                                                    {!editMode && (
                                                        <IonButton onClick={editStay} shape="round" expand="block" color="dark">
                                                            <IonLabel>Edit Stay</IonLabel>
                                                        </IonButton>
                                                    )}
                                                </>

                                            ) : (
                                                <div>
                                                    <p style={{ textAlign: 'center' }}>
                                                        You can edit this stay up to 3 days after making the stay and 3 days before the stay begins.<br />
                                                        Outside of those windows, please cancel the reservation and rebook.</p>
                                                </div>
                                            )
                                        }

                                        {!editMode && (
                                            <IonButton
                                                disabled={reservation.isCancelled}
                                                id="confirm-cancel-alert"
                                                expand="block"
                                                color="danger"
                                                shape="round"
                                                style={{ marginTop: '20px' }}>
                                                Cancel Stay
                                                {cancelling && <IonSpinner slot="end" />}
                                            </IonButton>
                                        )}

                                        <IonAlert
                                            trigger="confirm-cancel-alert"
                                            header="Are you sure you want to cancel this stay?"
                                            // TODO check MEMBERSHIP_TYPE
                                            // message={`You will receive ${Math.floor(numberOfNights / 2)} night credits which you can use to book future stays.`}
                                            buttons={[{
                                                text: 'Back',
                                                role: 'primary',

                                            }, {
                                                text: 'Agree',
                                                role: 'destructive',
                                                handler: cancelStay
                                            }]}
                                        ></IonAlert>
                                    </div>
                                )}

                            </IonContent>

                        </IonModal>
                    </IonButton>
                </IonCardContent>
            </IonCard >
        </>

    )
}

export default StayCard;
