import { IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonDatetime, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonRow, IonSelect, IonSelectOption, IonSpinner, IonTitle, IonToolbar, isPlatform } from "@ionic/react"
import { useEffect, useRef, useState } from "react";

import "./BookingSearch.css";
import { calendar, close } from "ionicons/icons";
import { differenceInCalendarDays, format, isAfter, isBefore, isSameDay } from 'date-fns'
import { ReservationDetails } from "../models/reservationDetails.model";

interface BookingSearchProps {
    parentPage: HTMLElement | null;
    resources: any[];
    searchResources: (startDate: string, endDate: string, location: number, numberOfNights: number) => any;
    fetchingResources: boolean;
    searchingResources: boolean;
    reservationDetails: ReservationDetails,
    setReservationDetails: (reservationDetails: ReservationDetails) => void;
    resetResources: () => void;
}

const BookingSearch: React.FC<BookingSearchProps> = ({
    parentPage,
    resources,
    fetchingResources,
    searchResources,
    searchingResources,
    reservationDetails,
    setReservationDetails,
    resetResources
}) => {

    // const [location, setLocation] = useState<number>(-1);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [numberOfNights, setNumberOfNights] = useState<number>(0);

    const [startDateLabel, setStartDateLabel] = useState<string>("Select start date");
    const [endDateLabel, setEndDateLabel] = useState<string>("Select end date");

    const datetime = useRef<null | HTMLIonDatetimeElement>(null);
    const startDateModal = useRef<HTMLIonModalElement>(null);
    const endDateModal = useRef<HTMLIonModalElement>(null);

    const today = new Date().toISOString();
    const max = new Date('Sept 30, 2023').toISOString();

    useEffect(() => {
        if (!startDate || startDate === '') return;
        if (!endDate || endDate === '') return;
        const start = new Date(startDate);
        const end = new Date(endDate);
        const nights = differenceInCalendarDays(end, start)
        setNumberOfNights(nights);
    }, [startDate, endDate, setNumberOfNights])

    const handleStartDatePicker = (e: any) => {
        if (!e.detail.value) return;
        const zeroedDate = format(new Date(e.detail.value), 'yyyy-MM-dd')
        setStartDateLabel(format(new Date(e.detail.value), 'MMM dd, yyyy'))
        setStartDate(zeroedDate)

        if (endDate && isAfter(new Date(zeroedDate), new Date(endDate))) {
            setEndDate('');
            setEndDateLabel('Select a date')
        }
    }

    const handleEndDatePicker = (e: any) => {
        if (!e.detail.value) return;
        const zeroedDate = format(new Date(e.detail.value), 'yyyy-MM-dd')
        setEndDateLabel(format(new Date(e.detail.value), 'MMM dd, yyyy'))
        setEndDate(zeroedDate)

        if (startDate && isBefore(new Date(zeroedDate), new Date(startDate))) {
            setStartDate('');
            setStartDateLabel('Select a date')
        }
    }

    const highlightDates = (isoString: string) => {
        if (!startDate || startDate === '') return undefined;
        if (startDate && (!endDate || endDate === '')) {
            if (isSameDay(new Date(startDate), new Date(isoString))) {
                return {
                    textColor: 'var(--ion-color-secondary-contrast)',
                    backgroundColor: 'var(--ion-color-secondary)',
                };
            }
        }
        if (!startDate || startDate === '' || !endDate || endDate === '') return undefined;

        const date = new Date(isoString);
        const isSameOrAfter = isSameDay(date, new Date(startDate)) || isAfter(date, new Date(startDate));
        const isSameOfBefore = isSameDay(date, new Date(endDate)) || isBefore(date, new Date(endDate));
        if (isSameOrAfter && isSameOfBefore) {
            return {
                textColor: 'var(--ion-color-primary-contrast)',
                backgroundColor: 'var(--ion-color-primary)',
            };
        }

        return undefined;
    }

    const selectResource = (resourceId: number) => {
        const resource = resources.find(r => r.id === resourceId);
        console.log('selecting resource: ', resource)
        setReservationDetails({
            ...reservationDetails,
            locationId: resourceId,
            locationName: resource?.name
        })
    }

    const onSearchResources = () => {
        if (!startDate || !endDate) return;

        searchResources(startDate, endDate, reservationDetails.locationId!, numberOfNights)
    }

    const clearSearch = () => {
        setStartDate('');
        setEndDate('');
        setStartDateLabel('Select a date');
        setEndDateLabel('Select a date');
        setNumberOfNights(0);
        setReservationDetails({
            startDate: '',
            endDate: '',
            numberOfNights: 0,
            locationId: -1,
            locationName: null,
            hasSearched: false
        })
        resetResources();
    }


    return (
        <div className="booking-search-container">
            <div className="header">Find your next stay</div>

            <IonCard color="secondary" style={{ margin: "auto" }}>
                <IonCardContent>
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12" sizeLg="4">

                                {/* Start Date */}
                                <IonItem className="main-item" lines="none" id="open-startdate-input">
                                    <IonInput
                                        label={startDateLabel}
                                        labelPlacement="floating"
                                        placeholder="Select a date"
                                    />
                                    <IonIcon icon={calendar} slot="end"></IonIcon>
                                    <IonModal
                                        ref={startDateModal}
                                        presentingElement={parentPage!}
                                        trigger="open-startdate-input"
                                    >
                                        <IonHeader>
                                            <IonToolbar>
                                                <IonButtons slot="start">
                                                    <IonButton onClick={() => startDateModal.current?.dismiss()}>
                                                        <IonIcon icon={close} />
                                                    </IonButton>
                                                </IonButtons>
                                                <IonTitle>Select Start Date</IonTitle>
                                            </IonToolbar>
                                        </IonHeader>
                                        <IonContent className="ion-padding">
                                            {/* Start Date Picker */}
                                            <IonDatetime
                                                style={{ margin: '0 auto' }}
                                                ref={datetime}
                                                preferWheel={false}
                                                color="primary"
                                                highlightedDates={highlightDates}
                                                presentation="date"
                                                min={today}
                                                max={max}
                                                onIonChange={(e) => handleStartDatePicker(e)}
                                            />
                                            {startDate && startDate !== '' && <IonButton style={{ marginTop: '20px' }} expand="block" shape="round" color="primary" onClick={() => startDateModal.current?.dismiss()}>Next</IonButton>}

                                        </IonContent>
                                    </IonModal>
                                </IonItem>
                            </IonCol>

                            {
                                startDate && startDate !== '' && (
                                    <IonCol size="12" sizeLg="4">
                                        <IonItem className="main-item" lines="none" id="open-enddate-input">
                                            <IonInput
                                                label={endDateLabel}
                                                labelPlacement="floating"
                                                placeholder="Select a date"
                                            />
                                            <IonIcon icon={calendar} slot="end"></IonIcon>
                                            <IonModal
                                                ref={endDateModal}
                                                presentingElement={parentPage!}
                                                trigger="open-enddate-input"

                                            >
                                                <IonHeader>
                                                    <IonToolbar>
                                                        <IonButtons slot="start">
                                                            <IonButton onClick={() => endDateModal.current?.dismiss()}>
                                                                <IonIcon icon={close} />
                                                            </IonButton>
                                                        </IonButtons>
                                                        <IonTitle>Select End Date</IonTitle>
                                                    </IonToolbar>
                                                </IonHeader>
                                                <IonContent className="ion-padding">
                                                    <IonDatetime
                                                        style={{ margin: '0 auto' }}
                                                        ref={datetime}
                                                        preferWheel={false}
                                                        color="primary"
                                                        presentation="date"
                                                        highlightedDates={highlightDates}
                                                        min={startDate!}
                                                        max={max}
                                                        onIonChange={(e) => handleEndDatePicker(e)}
                                                    />
                                                    {endDate && endDate !== '' && <IonButton style={{ marginTop: '20px' }} expand="block" shape="round" color="primary" onClick={() => endDateModal.current?.dismiss()}>Next</IonButton>}
                                                </IonContent>
                                            </IonModal>
                                        </IonItem>
                                    </IonCol>
                                )
                            }

                            <IonCol size="12" sizeLg="4">
                                {/* Location Picker */}
                                <IonItem className="main-item" lines="none">

                                    {resources.length > 0 && (
                                        <IonSelect
                                            value={reservationDetails.locationId}
                                            aria-label="location"
                                            labelPlacement="floating"
                                            interface={isPlatform('mobile') ? 'action-sheet' : 'popover'}
                                            placeholder="All Houses"
                                            onIonChange={(e: any) => selectResource(e.detail.value)}>
                                            <IonSelectOption value={-1}>All Houses</IonSelectOption>
                                            {resources.map((resource: any) => {
                                                return <IonSelectOption
                                                    key={resource.id}
                                                    value={resource.id}>
                                                    {resource.name}
                                                </IonSelectOption>
                                            }
                                            )}
                                        </IonSelect>
                                    )}
                                    {fetchingResources && (
                                        <>
                                            <IonLabel>&nbsp;</IonLabel>
                                            <IonSpinner slot="end" />
                                        </>

                                    )}

                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" sizeLg="4" offsetLg="4">
                                <IonButton
                                    expand="block"
                                    color="dark"
                                    shape="round"
                                    disabled={!startDate || !endDate}
                                    onClick={onSearchResources}
                                    style={{ marginTop: '20px' }}>
                                    Search
                                    {searchingResources && (
                                        <IonSpinner style={{ marginLeft: '20px' }} color="light" slot="end" />
                                    )}
                                </IonButton>

                                {reservationDetails.hasSearched && (
                                    <IonButton

                                        expand="block"
                                        color="dark"
                                        shape="round"
                                        fill="outline"
                                        style={{ marginTop: '20px' }}
                                        onClick={clearSearch}
                                    >
                                        Clear Search
                                    </IonButton>

                                )}
                            </IonCol>
                        </IonRow>



                    </IonGrid>
                </IonCardContent>
            </IonCard>


        </div>
    );

}

export default BookingSearch;