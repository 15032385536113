import { IonCol, IonContent, IonGrid, IonHeader, IonLabel, IonPage, IonRefresher, IonRefresherContent, IonRow, IonSegment, IonSegmentButton, IonText, IonTitle, IonToolbar, RefresherEventDetail } from '@ionic/react';
import './StaysPage.css';
import { useEffect, useRef, useState } from 'react';
import StayCard from '../components/StayCard';
import { isAfter, isBefore, setHours, setMinutes, setSeconds } from 'date-fns';
import { getAuth } from 'firebase/auth';
import { collection, query, where } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useFirestoreQuery } from "@react-query-firebase/firestore";
import useToastError from '../hooks/useToastError';
import { Stay } from '../models/stay.model';
import { Member } from '../models/member.model';


interface StaysProps {
  member: Member;
}

const StaysPage: React.FC<StaysProps> = ({ member }) => {
  // const location = useLocation();
  const displayError = useToastError();
  const page = useRef(null);
  const [presentingElement, setPresentingElement] = useState<HTMLElement | null>(null);
  const [allStays, setAllStays] = useState<any[]>([]);
  const [pastStays, setPastStays] = useState<any[]>([]);
  const [currentStays, setCurrentStays] = useState<any[]>([]);
  const [futureStays, setFutureStays] = useState<any[]>([]);
  // const [fetchingStays, setFetchingStays] = useState<boolean>(false);
  const [segment, setSegment] = useState<'past' | 'current' | 'future' | 'cancelled'>('current');


  const auth = getAuth();
  const uid = auth.currentUser!.uid;
  const ref = query(collection(db, 'STAYS'), where('MEMBER_ID', '==', uid));
  const { isError, data, refetch } = useFirestoreQuery(["products"], ref, {
    subscribe: true,
  });

  useEffect(() => {
    if (data) {
      const now = setHours(setMinutes(setSeconds(new Date(), 0), 0), 0);
      const stays = data.docs.map((snapshot: any) => {
        const doc = snapshot.data() as Stay;
        const START_DATE = new Date(`${doc.START_DATE} 00:00:00`);
        const END_DATE = new Date(`${doc.END_DATE} 23:59:59`);
        return { 
          ...doc,
          _UID: snapshot.id,
          START_DATE,
          END_DATE,
          isCancelled: doc.status > 7,
          isFuture: isAfter(START_DATE, now),
          isPast: isBefore(END_DATE, now),
          isCurrent: isBefore(START_DATE, now) && isAfter(END_DATE, now)
        }
      })


      setPastStays([]);
      setCurrentStays([]);
      setFutureStays([]);
      setAllStays([]);
      if (stays.length === 0) { return }
      console.log('Stays: ', stays)
      const sorted = stays.sort((a: any, b: any) => {
        return new Date(a.START_DATE).getTime() - new Date(b.START_DATE).getTime();
      });

      setFutureStays(sorted.filter((x: any) => x.isFuture));
      setCurrentStays(sorted.filter((x: any) => x.isCurrent));
      setPastStays(sorted.filter((x: any) => x.isPast));
      setAllStays(sorted);

      // sorted.forEach((x: any) => {
      //   console.log('Parsing stay status: ', x)
      //   if (x.status <= 7) {
      //     x = { ...x, isCancelled: false }
      //   } else {
      //     x = { ...x, isCancelled: true }
      //   }


      //   if (isBefore(now, new Date(x.START_DATE))) {
      //     x = { ...x, isFuture: true }
      //     setFutureStays((futureStays: any) => [...futureStays, x]);
      //   } else {
      //     if (isBefore(now, new Date(x.END_DATE))) {
      //       x = { ...x, isCurrent: true }
      //       setCurrentStays((currentStays: any) => [...currentStays, x]);
      //     } else {
      //       x = { ...x, isPast: true }
      //       setPastStays((pastStays: any) => [...pastStays, x]);
      //     }
      //   }
      //   setAllStays((allStays: any) => [...allStays, x]);
      // })


      if (currentStays.length === 0) {
        setSegment('future');
      }

    }
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps 

  useEffect(() => {
    setPresentingElement(page.current); // for child modals
  }, [])

  // const fetchStays = () => {
  //   setFetchingStays(true);
  //   const functions = getFunctions();
  //   const params = {
  //     startDate: sub(new Date(), { days: 90 }).toISOString(),
  //     endDate: add(new Date(), { days: 90 }).toISOString(),
  //   }
  //   return httpsCallable(functions, 'planyo-listReservations')(params)
  //     .then((result: any) => {
  //       const { data, error } = result;
  //       if (error) {
  //         throw new Error(error)
  //       } else {

  //       }
  //     })
  //     .finally(() => {
  //       setFetchingStays(false);
  //     })
  // }

  const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    refetch();
    setTimeout(() => {
      event.detail.complete();
    }, 1000)

  }

  if (isError) {
    displayError({ message: 'Error fetching stays' });
  }

  return (
    <IonPage ref={page}>
      <IonHeader>
        <IonToolbar color="secondary">
          <IonTitle>Your Stays</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Your Stays</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid className="page-wrapper">
          <IonRow>
            <IonCol>
              <IonSegment color="dark" value={segment} onIonChange={(e: any) => setSegment(e.detail.value)}>
                <IonSegmentButton value="current">
                  <IonLabel>Current</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="future">
                  <IonLabel>Future</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="past">
                  <IonLabel>Past</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="cancelled">
                  <IonLabel>Cancelled</IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonCol>
          </IonRow>
          <IonRow>

            {segment === 'current' && currentStays.filter((stay) => !stay.isCancelled).map((stay) => {
              return (
                <IonCol size="12" sizeMd="6" sizeLg="4" key={stay._UID}>
                  <StayCard reservation={stay} parentPage={presentingElement} member={member} />
                </IonCol>
              )
            })}

            {segment === 'future' && futureStays.filter((stay) => !stay.isCancelled).map((stay) => {
              return (
                <IonCol size="12" sizeMd="6" sizeLg="4" key={stay._UID}>
                  <StayCard reservation={stay} parentPage={presentingElement} member={member} />
                </IonCol>
              )
            })}

            {segment === 'past' && pastStays.filter((stay) => !stay.isCancelled).map((stay) => {
              return (
                <IonCol size="12" sizeMd="6" sizeLg="4" key={stay._UID}>
                  <StayCard reservation={stay} parentPage={presentingElement} member={member} />
                </IonCol>
              )
            })}

            {segment === 'cancelled' && allStays.filter((stay) => stay.isCancelled).map((stay) => {
              return (
                <IonCol size="12" sizeMd="6" sizeLg="4" key={stay._UID}>
                  <StayCard reservation={stay} parentPage={presentingElement} member={member} />
                </IonCol>
              )
            })}

            {segment === 'current' && currentStays.filter((stay) => !stay.isCancelled).length === 0 && (
              <IonCol>
                <IonText>
                  <h3 className="no-stays">No current stays</h3>
                </IonText>
              </IonCol>

            )}

            {segment === 'future' && futureStays.filter((stay) => !stay.isCancelled).length === 0 && (
              <IonCol>
                <IonText>
                  <h3 className="no-stays">No future stays</h3>
                </IonText>
              </IonCol>
            )}

            {segment === 'past' && pastStays.filter((stay) => !stay.isCancelled).length === 0 && (
              <IonCol>
                <IonText>
                  <h3 className="no-stays">No past stays</h3>
                </IonText>
              </IonCol>
            )}

            {segment === 'cancelled' && allStays.filter((stay) => stay.isCancelled).length === 0 && (
              <IonCol>
                <IonText>
                  <h3 className="no-stays">No cancelled stays</h3>
                </IonText>
              </IonCol>
            )}

          </IonRow>
        </IonGrid>

      </IonContent>
      {/* <IonLoading isOpen={fetchingStays} duration={30000} /> */}
    </IonPage>
  );
};

export default StaysPage;
