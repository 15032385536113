import { useEffect, useRef, useState } from "react";
import {
    IonAlert,
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonInput,
    IonItem,
    IonLabel,
    IonSpinner,
} from '@ionic/react';
import { getFunctions, httpsCallable } from 'firebase/functions';

import {
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    User,
    UserCredential,
} from "firebase/auth";
import { auth, db } from "../config/firebase";
import useToastError from "../hooks/useToastError";
import useSuccessToast from "../hooks/useSuccessToast";
import { useHistory, useLocation } from "react-router";
import { doc, getDoc } from 'firebase/firestore';
import { Member } from "../models/member.model";

interface LoginProps {
    user: User | null;
    setUser: (user: User | null) => void;
    member: any;
    setMember: (member: Member) => void;
}

const Login: React.FC<LoginProps> = ({ user, setUser, member, setMember }) => {
    const displayError = useToastError();
    const displaySuccess = useSuccessToast()
    const history = useHistory();
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [haveAccount, setHaveAccount] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [formPrepopulated, setFormPrepopulated] = useState<boolean>(false);

    const passwordInput = useRef<any>(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const emailParam = queryParams.get('email');
    const firstNameParam = queryParams.get('firstname');
    const lastNameParam = queryParams.get('lastname');

    useEffect(() => {

        if (emailParam && firstNameParam && lastNameParam) {
            setEmail(emailParam!);
            setFirstName(firstNameParam!);
            setLastName(lastNameParam!);
            setFormPrepopulated(true);
            setTimeout(() => {
                if (passwordInput.current) {
                    passwordInput.current.setFocus();
                }
            }, 100)

        }
    }, [emailParam, firstNameParam, lastNameParam])     // eslint-disable-line react-hooks/exhaustive-deps


    const createMember = async (authUser: UserCredential) => {
        if (!firstName || !lastName || !email || !password) {
            displayError({ message: 'Missing info. Please complete the form.' })
            return;
        }
        setLoading(true)
        const { uid } = authUser.user;
        const functions = getFunctions();
        const result: any = await httpsCallable(functions, 'member-setupMember')({ uid, email, firstName, lastName })
        if (result.data.error) {
            switch (result.data.error) {
                case 'No hubspot record found':
                    displayError({ message: 'Error creating your account. Email support@kift.com ' })
                    break;
                default:
                    displayError({ message: `The internet isn't working right. OpenAI will have this fixed yesterday.` })
            }
            setLoading(false)
        } else {
            getMember(authUser);
        }
    }

    const getMember = async (authUser: UserCredential) => {
        setLoading(true)
        const result = await getDoc(doc(db, 'MEMBERS', authUser.user.uid));
        if (result.exists()) {
            setUser(authUser.user)
            setMember(result.data() as Member);
            history.push('/home');
        } else {
            displayError({ message: 'Error talking to the internet. ChatGPT has been notified' })
        }
        setLoading(false)
    }


    const signIn = () => {
        setLoading(true);
        if (email && password) {
            const authInstance = auth();
            signInWithEmailAndPassword(authInstance, email, password)
                .then((userCredential: UserCredential) => {
                    getMember(userCredential);

                })
                .catch((error) => {
                    console.error(error)
                    displayError(error)
                    setLoading(false)
                });
        } else {
            displayError({ message: 'Missing email or password' })
            setLoading(false)
        }
    }

    const signUp = () => {

        if (!firstName || !lastName || !email || !password) {
            displayError({ message: 'Missing info. Please complete the form.' })
            return;
        }

        const authInstance = auth();
        createUserWithEmailAndPassword(authInstance, email, password)
            .then((userCredential: UserCredential) => {
                createMember(userCredential);
            })
            .catch((error) => {
                switch (error) {
                    case 'auth/email-already-in-use':
                        displayError({ message: 'Email already in use' })
                        break;
                    default:
                        displayError(error)
                }
                console.error(error)
                setLoading(false)
            });
    }


    return (
        <>
            {!haveAccount ? (<div className="login-wrapper">
                <IonCard style={{ maxWidth: '600px', margin: '40px 16px' }}>
                    <IonCardTitle>
                        <IonCardHeader>Welcome back</IonCardHeader>
                    </IonCardTitle>
                    <IonCardContent>
                        <IonItem fill="outline">
                            <IonLabel position="floating">Email</IonLabel>
                            <IonInput
                                value={email}
                                onIonInput={(e: any) => setEmail(e.detail.value!)}
                            />
                        </IonItem>

                        <IonItem fill="outline">
                            <IonLabel position="floating">Password</IonLabel>
                            <IonInput
                                value={password}
                                type="password"
                                onIonInput={(e: any) => setPassword(e.detail.value!)}
                                clearInput
                            />
                        </IonItem>

                        <IonButton style={{ marginTop: '20px' }} shape="round" expand="block" onClick={signIn}>Sign In
                            {loading && <IonSpinner slot="end" style={{ marginLeft: '20px' }} />}</IonButton>
                        <IonButton style={{ marginTop: '60px' }} color="dark" shape="round" expand="block" onClick={() => setHaveAccount(true)}>Create Account</IonButton>
                        <IonButton id="password-reset" style={{ marginTop: '40px' }} fill="clear" expand="block" color="dark" shape="round">Forgot Password?</IonButton>
                        <IonAlert
                            trigger="password-reset"
                            header="Enter your email"
                            buttons={[{
                                text: 'Cancel',
                                role: 'cancel',

                            }, {
                                text: 'Send Reset',
                                handler: async (value) => {
                                    console.log(value[0])
                                    try {
                                        await sendPasswordResetEmail(auth(), value[0])
                                        displaySuccess('Reset email sent')

                                    } catch (e) {
                                        displayError({ message: 'Error sending reset email. Make sure to use the email address associated with your membership plan' })
                                        console.error(e)
                                    }
                                }
                            }]}
                            inputs={[
                                {
                                    placeholder: 'Name',
                                    type: 'email',

                                },
                            ]}
                        ></IonAlert>
                    </IonCardContent>
                </IonCard>
            </div>) : (
                <div className="signup-wrapper">
                    <IonCard style={{ maxWidth: '600px', margin: '40px 16px' }}>
                        <IonCardHeader>
                            <p>👋 Welcome to the new Kift booking system! To create an account, click the link in the welcome email.</p>
                            <IonCardTitle>Create your account</IonCardTitle>
                        </IonCardHeader>

                        <IonCardContent>
                            {formPrepopulated && (
                                <>
                                    <IonItem>
                                        <IonLabel position="floating">First Name</IonLabel>
                                        <IonInput
                                            disabled={true}
                                            autocomplete="off"
                                            fill="outline"
                                            value={firstName}
                                            onIonChange={(e: any) => setFirstName(e.detail.value!)}
                                        />
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel position="floating">Last Name</IonLabel>
                                        <IonInput
                                            disabled={true}
                                            autocomplete="off"
                                            fill="outline"
                                            value={lastName}
                                            onIonChange={(e: any) => setLastName(e.detail.value!)}
                                        />
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel position="floating">Email</IonLabel>
                                        <IonInput
                                            disabled={true}
                                            autocomplete="off"
                                            fill="outline"
                                            value={email}
                                            onIonChange={(e: any) => setEmail(e.detail.value!)}
                                        />
                                    </IonItem>

                                    <IonItem >
                                        <IonLabel position="floating">Password</IonLabel>
                                        <IonInput
                                            ref={passwordInput}
                                            // ref={(ref) => passwordInput.current = ref}
                                            fill="outline"
                                            value={password}
                                            type="password"
                                            autocomplete="off"
                                            onIonChange={(e: any) => setPassword(e.detail.value!)}
                                            clearInput
                                        />
                                    </IonItem>

                                    <IonButton style={{ marginTop: '20px' }} expand="block" shape="round" onClick={signUp}>Sign Up
                                        {loading && <IonSpinner slot="end" style={{ marginLeft: '20px' }} />}
                                    </IonButton>
                                </>
                            )}
                            <IonButton style={{ marginTop: '60px' }} color="dark" expand="block" shape="round" onClick={() => setHaveAccount(false)}>Already have an account?</IonButton>
                        </IonCardContent>
                    </IonCard>
                </div>
            )}
        </>
    )

}

export default Login;