import { useIonToast } from "@ionic/react";
import { useCallback } from "react";

// Default config for displaying an error

function useSuccessToast() {
  const [present] = useIonToast();

  const showSuccess = useCallback(
    (message: string) =>
      present({
        message,
        duration: 15000,
        color: "primary",
        position: "top",
        buttons: [
          {
            text: "Done",
            role: "cancel",
          },
        ],
      }),
    [present]
  );

  return showSuccess;
}

export default useSuccessToast;
