import React, { useEffect, useState } from "react";
import {
    PaymentElement,
    // LinkAuthenticationElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import { IonButton, IonLabel, IonSpinner } from "@ionic/react";

interface CheckoutFormProps {
    memberEmail: string;
    makeReservation: () => Promise<void>;
    canMakeReservation: () => Promise<boolean>;
    doneCallback: () => void;

}

const CheckoutForm: React.FC<CheckoutFormProps> = ({ makeReservation, canMakeReservation, doneCallback }) => {
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret)
            .then(({ paymentIntent }) => {
                switch (paymentIntent!.status) {
                    case "succeeded":
                        setMessage("Payment succeeded!");
                        break;
                    case "processing":
                        setMessage("Your payment is processing.");
                        break;
                    case "requires_payment_method":
                        setMessage("Your payment was not successful, please try again.");
                        break;
                    default:
                        setMessage("Something went wrong.");
                        break;
                }
            });

        // setEmail(memberEmail)

    }, [stripe]);

    const handleSubmit = async (e: any) => {

        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const canProceed = await canMakeReservation();
        if (!canProceed) {
            return;
        }

        // TODO save this in firebase
        setMessage('Reservation dates confirmed, booking now...')
        makeReservation()
            .then(async () => {

            const { paymentIntent, error } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    // TODO Make sure to change this to your payment completion page
                    return_url: "http://localhost:8100",
                },
                redirect: 'if_required'
            });
    
            if (paymentIntent && paymentIntent.status === 'succeeded') {
                setIsLoading(false);
                doneCallback();
            }
    
            if (error) {
                console.error('confirm payment error: ', error)
                if ((error && error.type === "card_error") ||
                    (error && error.type === "validation_error")) {
                    setMessage(error.message!);
                } else {
                    setMessage("An unexpected error occurred.");
                }
                setIsLoading(false);
            }
    
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Otherwise, your customer will be redirected to
            // your `return_url`. For some payment methods like iDEAL, your customer will
            // be redirected to an intermediate site first to authorize the payment, then
            // redirected to the `return_url`.

        }).catch((error) => {
            console.error('[CHECKOUT] make reservation error: ', error)
        });


    };

    const paymentElementOptions: any = {
        layout: "tabs",
    }

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement id="payment-element" options={paymentElementOptions} />

            <IonButton
                color="dark"
                expand="block"
                shape="round"
                disabled={isLoading || !stripe || !elements}
                form="payment-form"
                type="submit">
                <IonLabel>Book Now</IonLabel>
                {isLoading && <IonSpinner style={{ marginLeft: '20px' }} slot="end" />}
            </IonButton>

            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}

export default CheckoutForm;