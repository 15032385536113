import { IonCol, IonContent, IonGrid, IonHeader, IonPage, IonRow, IonTitle, IonToolbar } from "@ionic/react";

import Login from '../components/Login';
import { User } from "firebase/auth";

interface LoginPageProps {
    user: any;
    setUser: (user: User | null) => void;
    member: any;
    setMember: (member: any) => void;
}

const LoginPage: React.FC<LoginPageProps> = ({ user, setUser, member, setMember }) => {

    return (
        <IonPage>
            <IonHeader className="ion-no-border">
                <IonToolbar color="secondary">
                    <IonTitle></IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol size="12" sizeLg="6" offsetLg="3">
                            <Login
                                user={user}
                                setUser={setUser}
                                member={member}
                                setMember={setMember} />
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
        </IonPage>
    )

}

export default LoginPage;
