

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import CheckoutForm from './CheckoutForm';

import './StripeCheckout.css';
import config from '../config/stripe';

const stripePromise = loadStripe(config.pk)
interface StripeCheckoutProps {
    makeReservation: () => Promise<void>;
    canMakeReservation: () => Promise<boolean>;
    clientSecret: string;
    email: string;
    doneCallback: () => void;
}

const StripeCheckout: React.FC<StripeCheckoutProps> =
    ({ makeReservation, canMakeReservation, clientSecret, email , doneCallback}) => {

        
        //   const appearance = {
        //     theme: 'stripe',
        //   };
        const options = {
            clientSecret,
            // appearance,
        };

        return (
            <div className="App">
                {clientSecret && (
                    <Elements options={options} stripe={stripePromise}>
                        <CheckoutForm
                            memberEmail={email}
                            makeReservation={makeReservation}
                            canMakeReservation={canMakeReservation}
                            doneCallback={doneCallback}
                        />
                    </Elements>
                )}
            </div>
        );

    }

export default StripeCheckout;