import { IonButton, IonCol, IonDatetime, IonGrid, IonLabel, IonRow, IonSpinner } from "@ionic/react";
import { Stay } from "../models/stay.model";
import { useEffect, useRef, useState } from "react";
import { add, differenceInCalendarDays, format, isAfter, isBefore, isSameDay } from "date-fns";
// import { MemberContext } from "../contexts/member";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Member } from "../models/member.model";
import UpdateStayCart from "./UpdateStayCart";
import useToastError from "../hooks/useToastError";
import { formatEndDate, formatStartDate } from "../utilities/time";
import useSuccessToast from "../hooks/useSuccessToast";

interface EditStayProps {
    member: Member;
    stay: Stay;
    setEditMode: (editMode: boolean) => void;
    showModal: (show: boolean) => void;
}

const EditStay: React.FC<EditStayProps> = ({ stay, setEditMode, showModal, member }) => {
    const displayError = useToastError();
    const displaySuccess = useSuccessToast();

    const startDateRef = useRef<null | HTMLIonDatetimeElement>(null);
    const endDateRef = useRef<null | HTMLIonDatetimeElement>(null);

    // const { member } = useContext(MemberContext);
    const [updatedStartDate, setUpdatedStartDate] = useState<string>("");
    const [updatedEndDate, setUpdatedEndDate] = useState<string>("");
    const [initDuration, setInitDuration] = useState<number>(0);
    const [diffDuration, setDiffDuration] = useState<number>(0);
    const [updating, setUpdating] = useState<boolean>(false);
    const [startColorTheme, setStartColorTheme] = useState<string>('primary')
    const [endColorTheme, setEndColorTheme] = useState<string>('primary')

    const initStartDate = format(new Date(stay.START_DATE), 'yyyy-MM-dd')
    const initEndDate = format(new Date(stay.END_DATE), 'yyyy-MM-dd')

    const today = new Date().toISOString();
    const max = add(new Date(), { days: 90 }).toISOString();

    useEffect(() => {
        if (stay) {
            console.log('stay: ', stay)
            setUpdatedStartDate(initStartDate);
            setUpdatedEndDate(initEndDate);

            const isd = new Date(initStartDate)
            const ied = new Date(initEndDate)
            const initN = differenceInCalendarDays(ied, isd)
            setInitDuration(initN)

        }
    }, [stay, initStartDate, initEndDate])

    useEffect(() => {
        if (updatedStartDate && updatedEndDate) {
            const usd = new Date(updatedStartDate)
            const ued = new Date(updatedEndDate)
            const newN = differenceInCalendarDays(ued, usd)
            setDiffDuration(newN - initDuration)

            if (isBefore(new Date(initStartDate), new Date(updatedStartDate))) {
                setStartColorTheme('danger')
            } else {
                setStartColorTheme('primary')
            }

            if (isBefore(new Date(initEndDate), new Date(updatedEndDate))) {
                setEndColorTheme('danger')
            } else {
                setEndColorTheme('primary')
            }
        }
    }, [updatedStartDate, updatedEndDate, initDuration, initStartDate, initEndDate])


    const highlightDates = (isoString: string) => {
        const date = new Date(isoString);

        if (updatedStartDate && updatedStartDate !== '') {
            if (isBefore(date, new Date(updatedStartDate))) {
                return undefined;
            }
        }

        if (updatedEndDate && updatedEndDate !== '') {
            if (isAfter(date, new Date(updatedEndDate))) {
                return undefined;
            }
        }

        const isSameOrAfterInit = isSameDay(date, new Date(initStartDate)) || isAfter(date, new Date(initStartDate));
        const isSameOfBeforeInit = isSameDay(date, new Date(initEndDate)) || isBefore(date, new Date(initEndDate));
        if (isSameOrAfterInit && isSameOfBeforeInit) {
            return {
                textColor: 'var(--ion-color-primary)',
                backgroundColor: 'rgba( var(--ion-color-primary-rgb), 0.25)',
            };
        }

        // if (!updatedStartDate || updatedStartDate === '' || !updatedEndDate || updatedEndDate === '') return undefined;

        const isSameOrAfterUpdated = isSameDay(date, new Date(updatedStartDate)) || isAfter(date, new Date(updatedStartDate));
        const isSameOfBeforeUpdated = isSameDay(date, new Date(updatedEndDate)) || isBefore(date, new Date(updatedEndDate));
        if (isSameOrAfterUpdated && isSameOfBeforeUpdated) {
            return {
                textColor: 'var(--ion-color-danger)',
                backgroundColor: 'rgba( var(--ion-color-danger-rgb), 0.25)',
            };
        }

        return undefined;
    }

    const handleStartDatePicker = (e: any) => {
        if (!e.detail.value) return;
        const zeroedDate = formatStartDate(e.detail.value)
        setUpdatedStartDate(zeroedDate)
    }

    const handleEndDatePicker = (e: any) => {
        if (!e.detail.value) return;
        const zeroedDate = formatEndDate(e.detail.value)
        setUpdatedEndDate(zeroedDate)
    }

    const canMakeReservation = (): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            const params = {
                startDate: formatStartDate(updatedStartDate),
                endDate: formatEndDate(updatedEndDate),
                resourceId: stay.RESOURCE_ID,
                excludedReservationId: stay.RESERVATION_ID,
                email: member?.EMAIL,
                firstName: member?.FIRST_NAME,
                lastName: member?.LAST_NAME,
            }
            const functions = getFunctions();
            httpsCallable(functions, 'planyo-canMakeReservation')(params)
                .then((result: any) => {
                    console.warn('check result: ', result)
                    const { data } = result;
                    if (data.error) {
                        console.error(data)
                        if (data.reason) {
                            reject(false)
                        } else {
                            throw new Error(data.error)
                        }
                    }
                    else {
                        if (data.canReserve) {
                            resolve(true);
                        } else {
                            reject(false);
                        }
                    }
                })
        })
    }

    /**
     * Update a stay that was booked on a season pass
     * Member can increase or decrease the dates of the stay
     */
    const updateStayOnPass = async () => {

        setUpdating(true);
        const available = await canMakeReservation();
        if (!available) {
            displayError({ message: 'The dates you selected are not available, please select different dates' })
            setUpdating(false);
        } else {
            const params = {
                startDate: formatStartDate(updatedStartDate),
                endDate: formatEndDate(updatedEndDate),
                reservationId: stay.RESERVATION_ID,
                _UID: stay._UID!
            }

            console.log('Updating Stay with params: ', params)
            const { data: response } = await httpsCallable(getFunctions(), 'planyo-updateReservation')(params)
            console.log('update result: ', response)
            const { error } = response as any;
            if (error) {
                displayError({ message: error })
                setUpdating(false);
            } else {
                setUpdating(false);
                displaySuccess('Your stay has been updated')
                showModal(false);
            }
        }
    }

    const reduceStayDuration = async () => {
        setUpdating(true);
        const available = await canMakeReservation();
        if (!available) {
            displayError({ message: 'The dates you selected are not available, please select different dates' })
            setUpdating(false);
        } else {
            const params = {
                startDate: formatStartDate(updatedStartDate),
                endDate: formatEndDate(updatedEndDate),
                reservationId: stay.RESERVATION_ID,
                _UID: stay._UID!,
                nightsDifference: Math.abs(diffDuration),
            }

            console.log('Updating Stay with params: ', params)
            const { data: response } = await httpsCallable(getFunctions(), 'planyo-reduceStayDuration')(params)
            console.log('update result: ', response)
            const { data, error } = response as any;
            if (error) {
                displayError({ message: error })
                setUpdating(false);
            } else {
                console.log('update success: ', data)
                setUpdating(false);
            }
        }
    }

    return (
        <>
            <IonGrid>
                <IonRow>
                    <IonCol size="12">
                        <h1>Edit your stay</h1>
                        <p>Original booking dates are in blue. New dates are in orange.</p>
                        <h3>Edit Details</h3>
                        {stay.MEMBERSHIP_TYPE === 'pass' ? (
                            <p>Because you booked your stay on an unlimited season pass, all changes to your stay are free.</p>
                        ) : (
                            <>
                                <p>Because you paid nightly for your stay, the new stay total will be calculated once you've selected your new dates.</p>
                                <p>If your new nightly total is less than the previous amount, nightly credits will be issued to your account.</p>
                            </>
                        )}
                    </IonCol>
                </IonRow>
                <IonRow>

                    <IonCol size="12" sizeMd="6">
                        {updatedStartDate && (
                            <IonDatetime

                                color={startColorTheme}
                                presentation="date"
                                preferWheel={false}
                                ref={startDateRef}
                                highlightedDates={highlightDates}
                                min={today}
                                max={max}
                                value={updatedStartDate}
                                onIonChange={(e) => handleStartDatePicker(e)}
                            >
                                <span slot="title">Select a New Start Date</span>
                            </IonDatetime>
                        )}

                    </IonCol>
                    <IonCol size="12" sizeMd="6">
                        {updatedEndDate && (
                            <IonDatetime
                                showDefaultTitle={true}
                                color={endColorTheme}
                                presentation="date"
                                preferWheel={false}
                                ref={endDateRef}
                                highlightedDates={highlightDates}
                                min={today}
                                max={max}
                                value={updatedEndDate}
                                onIonChange={(e) => handleEndDatePicker(e)}
                            >
                                <span slot="title">Select a New End Date</span>
                            </IonDatetime>
                        )}

                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12">

                        {stay.MEMBERSHIP_TYPE === 'pass' ? (
                            <>
                                {/* TODO Test this */}
                                <p style={{ marginTop: '20px' }}>This stay was booked using a pass and you still have an active pass so you can edit your reservation to unlimited nights.</p>
                                <IonButton
                                    onClick={updateStayOnPass}
                                    expand="block"
                                    shape="round"
                                    color="dark"
                                    disabled={updating}>
                                    <IonLabel>Update Stay</IonLabel>
                                    {updating && (<IonSpinner className="ion-margin-start" color="light" />)}
                                </IonButton>
                            </>

                        ) : (
                            <>
                                {diffDuration > 0 && (
                                    <div style={{ marginTop: '10px' }}>
                                        <strong>You're adding {diffDuration} nights to your stay.</strong>
                                        <UpdateStayCart
                                            stay={stay}
                                            newStartDate={updatedStartDate}
                                            newEndDate={updatedEndDate}
                                            numberOfNights={diffDuration} />

                                    </div>
                                )
                                }

                                {diffDuration < 0 && (
                                    <div style={{ marginTop: '10px' }}>
                                        <p><strong>You're removing {Math.abs(diffDuration)} nights from your stay.</strong></p>
                                        <p><strong>You'll be issued {Math.abs(diffDuration)} credits to your account.</strong></p>
                                        <IonButton
                                            expand="block"
                                            shape="round"
                                            color="dark"
                                            onClick={reduceStayDuration}
                                            disabled={updating}
                                        >Update Stay
                                            {updating && (<IonSpinner className="ion-margin-start" color="light" />)}
                                        </IonButton>
                                    </div>
                                )}
                            </>
                        )}



                        <IonButton
                            expand="block"
                            shape="round"
                            color="danger"
                            onClick={() => setEditMode(false)}
                            style={{ marginTop: '20px' }}>Cancel Changes</IonButton>


                    </IonCol>
                </IonRow>
            </IonGrid>
        </>
    )
}

export default EditStay;