import { useEffect, useState } from "react";

import { Member } from "../models/member.model";
import useToastError from "../hooks/useToastError";
import { getAuth } from "firebase/auth";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../config/firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useHistory } from "react-router";
import { Stay } from "../models/stay.model";
import { IonButton, IonItem, IonLabel, IonNote, IonCheckbox, IonSelect, IonSelectOption, IonSpinner, IonLoading, IonToast } from "@ionic/react";
import StripeCheckout from "./StripeCheckout";

interface UpdateStayCartProps {
    stay: Stay;
    newStartDate: string;
    newEndDate: string;
    numberOfNights: number;
}

interface PaymentDetails {
    numberOfNights: number;
    hasCopilot: boolean;
    hasPet: boolean;
    creditsToUse: number;

}

// const bookingAccessDefaults: MemberBookingAccess = {
//     canBook: false,
//     needsPayment: false,
//     passExpires: null,
//     membershipExpires: null
// }

const UpdateStayCart: React.FC<UpdateStayCartProps> = ({ stay, newStartDate, newEndDate, numberOfNights }) => {
    const displayError = useToastError();
    const history = useHistory();

    const [hasCopilot, setHasCopilot] = useState(false);
    const [hasPet, setHasPet] = useState(false);
    const [addOnFee, setAddOnFee] = useState(0);
    const [updatingReservation, setUpdatingReservation] = useState(false);
    const [successToast, setSuccessToast] = useState(false);
    const [errorToast, setErrorToast] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    // const [canBook, setCanBook] = useState(false);
    // const [needsPayment, setNeedsPayment] = useState(false);
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [checkoutLocked, setCheckoutLocked] = useState(false);
    const [clientSecret, setClientSecret] = useState("");
    const [gettingIntent, setGettingIntent] = useState(false);
    const [discountRate, setDiscountRate] = useState(0);
    // const [discountText, setDiscountText] = useState('');
    const [availableCredits, setAvailableCredits] = useState<any[]>([]);
    const [numberOfCreditsToUse, setNumberOfCreditsToUse] = useState(0);
    const [nightlySubtotal, setNightlySubtotal] = useState(0);
    const [bulkDiscount, setBulkDiscount] = useState(0);
    const [creditsDiscount, setCreditsDiscount] = useState(0);
    const [total, setTotal] = useState(0);
    // const [bookingAccess, setBookingAccess] = useState<MemberBookingAccess>(bookingAccessDefaults);

    useEffect(() => {
        (async () => {
            const member = await getDoc(doc(db, 'MEMBERS', getAuth().currentUser!.uid));
            const { FIRST_NAME, LAST_NAME, EMAIL } = member.data() as Member;
            setFirstName(FIRST_NAME);
            setLastName(LAST_NAME);
            setEmail(EMAIL);

            const auth = getAuth();
            const uid = auth.currentUser!.uid;
            const querySnapshot = await getDocs(query(collection(db, 'CREDITS'),
                where('MEMBER_ID', '==', uid),
                where('REDEEMED', '==', false)));
            setAvailableCredits(querySnapshot.docs.map(doc => doc.data()));

            // TODO in the future we might want to check their CURRENT status
            // for now we're just using the stauses at the time of booking
            
            // const result: any = await httpsCallable(getFunctions(), 'hubspot-bookingAccess')()
            // console.warn('Member Booking Access: ', result)
            // const { data, error } = result;
            // if (error || data.error) {
            //     displayError({ message: 'Error checking booking access' })
            //     console.error(error, data.error)
            //     throw new Error(error || data.error)
            // } else {
            //     setBookingAccess({
            //         canBook: data.canBook,
            //         needsPayment: data.needsPayment,
            //         passExpires: data.passExpires,
            //         membershipExpires: data.membershipExpires
            //     })
            // }
        })();
    }, [displayError])

    // useEffect(() => {
    //     if (bookingAccess.needsPayment) {
    //         setNeedsPayment(true);
            
    //     } else {
    //         setNeedsPayment(false);
    //     }

    //     if (bookingAccess.canBook) {
    //         setCanBook(true);
    //     } else {
    //         setCanBook(false);
    //     }
    // }, [bookingAccess]) // eslint-disable-line react-hooks/exhaustive-deps

    // calculate all the subtotals
    useEffect(() => {

        const n = numberOfNights;
        const effectiveN = n - numberOfCreditsToUse;
        setNightlySubtotal(n * 25)

        setAddOnFee((hasCopilot ? (n * 10) : 0) + (hasPet ? (n * 1) : 0));      // add on fees are calculated for the entire stay, not the effective nights with credits

        // the discount rate is based on the effective nights with credits
        if (effectiveN < 10) {
            setDiscountRate(0);
            // setDiscountText(`Book 10 nights or more to get 10% off`);
        } else if (effectiveN < 20) {
            setDiscountRate(0.1);
            // setDiscountText(`You've unlocked a 10% discount. Book 20 nights or more to get 20% off`);
        } else {
            setDiscountRate(0.2);
            // setDiscountText(`You've unlocked a 20% discount!`);
        }

        setBulkDiscount((effectiveN * 25) * discountRate)
        setCreditsDiscount(numberOfCreditsToUse * 25)

    }, [numberOfNights, numberOfCreditsToUse, hasCopilot, hasPet, discountRate])

    // calculate the total
    useEffect(() => {
        setTotal(nightlySubtotal + addOnFee - creditsDiscount - bulkDiscount)
    }, [nightlySubtotal, addOnFee, creditsDiscount, bulkDiscount])


    const canMakeReservation = (): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            const params = {
                startDate: newStartDate,
                endDate: newEndDate,
                resourceId: stay.RESOURCE_ID,
                excludedReservationId: stay.RESERVATION_ID,
                email,
                firstName,
                lastName
            }
            const functions = getFunctions();
            httpsCallable(functions, 'planyo-canMakeReservation')(params)
                .then((result: any) => {
                    const { data } = result;
                    if (data.error) {
                        if (data.reason) {
                            setErrorMessage(data.reason);
                            setErrorToast(true);
                            reject(false)
                        } else {
                            throw new Error(data.error)
                        }
                    }
                    else {
                        if (data.canReserve) {
                            resolve(true);
                        } else {
                            reject(false);
                        }
                    }
                })
        })
    }

    const cleanupAfterSuccess = () => {
        setUpdatingReservation(false);
        setSuccessToast(true);
        history.push('/stays?refresh=true');
    }


    const updateReservation = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            alert('calling parent function with local data')
            setUpdatingReservation(true);
            if (!firstName || !lastName || !email) {
                displayError({ message: 'Missing name and email to book your stay' })
                setUpdatingReservation(false);
                reject('Missing name and email to book your stay')
            }

            const params = {
                startDate: newStartDate,
                endDate: newEndDate,
                reservationId: stay.RESERVATION_ID,
                _UID: stay._UID,
            }

            console.log('Updating Booking with params: ', params)
            const functions = getFunctions();
            httpsCallable(functions, 'planyo-updateReservation')(params)
                .then((result: any) => {
                    const { data } = result;
                    if (data.error) {
                        if (data.response_message) {
                            setErrorMessage(data.response_message);
                            setErrorToast(true);
                            reject(data.response_message)
                        } else {
                            reject(data.error)
                            throw new Error(data.error)
                        }
                    }
                    else {
                        resolve(data)
                    }
                }).finally(() => { setUpdatingReservation(false) });
        })
    }

    const getStripeIntent = (stripeCustomerId: string) => {
        const functions = getFunctions();
        const paymentDetails: PaymentDetails = {
            numberOfNights: numberOfNights,
            hasCopilot,
            hasPet,
            creditsToUse: numberOfCreditsToUse
        }

        return httpsCallable(functions, 'stripe-createPaymentIntent')({
            paymentDetails,
            customerId: stripeCustomerId,
        })
            .then((result: any) => {
                const { data, error } = result;
                if (error) {
                    throw new Error(error)
                } else {
                    console.log('Stripe client secret: ', data.clientSecret);
                    setClientSecret(data.clientSecret);
                }
            })
            .catch(e => {
                console.error(e)
                displayError({ message: 'Error initiating payment. Please email support@kift.com' });
            })
            .finally(() => { });
    }

    const readyToCheckout = async () => {
        setGettingIntent(true);
        // get customer stripe id
        const auth = getAuth();
        const uid = auth.currentUser!.uid;
        const member = await getDoc(doc(db, 'MEMBERS', uid));
        const { STRIPE_ID } = member.data() as Member;

        // check to make sure payment amount isnt 0 (in the case of using credits)
        if (total === 0) {
            setGettingIntent(false)
            updateReservation().then(() => {
                cleanupAfterSuccess()
            }, err => {
                console.error(err)
                displayError({ message: 'Error making reservation. Please email support@kift.com ' })
            });
        } else {
            // create payment intent
            getStripeIntent(STRIPE_ID).then(() => {
                setCheckoutLocked(true)
            }, err => {
                console.error(err)
                displayError({ message: 'Error initiating payment. Please email support@kift.com' })
            }).finally(() => setGettingIntent(false))
        }


    }

    return (

        <>
            <IonLoading isOpen={updatingReservation} message="Rember to breath..." />
            {checkoutLocked ? (
                <>
                    <IonButton
                        onClick={() => setCheckoutLocked(false)}
                        expand="block"
                        shape="round"
                        color="danger"
                        style={{ marginTop: '30px', marginBottom: '30px' }}> Change Reservation
                    </IonButton>
                    <StripeCheckout
                        clientSecret={clientSecret}
                        email={email}
                        makeReservation={updateReservation}
                        canMakeReservation={canMakeReservation}
                        doneCallback={cleanupAfterSuccess} />
                </>
            ) : (
                <>
                    <div style={{
                        marginTop: '10px',
                        marginBottom: '5px',
                        fontWeight: '600',
                        textTransform: 'uppercase',
                        fontSize: '0.8em'
                    }}
                        className="divider">Add-ons</div>
                    <IonItem lines="none">
                        <IonCheckbox disabled={checkoutLocked} slot="start" labelPlacement="end" onIonChange={() => setHasCopilot(!hasCopilot)}>Include a co-pilot</IonCheckbox>
                        <IonNote slot="end">+$10/NIGHT</IonNote>
                    </IonItem>
                    <IonItem lines="none">
                        <IonCheckbox disabled={checkoutLocked} slot="start" labelPlacement="end" onIonChange={() => setHasPet(!hasPet)}>Include a pet</IonCheckbox>
                        <IonNote slot="end">+$1/NIGHT</IonNote>
                    </IonItem>

                    {availableCredits.length > 0 && (
                        <>
                            <div style={{
                                marginTop: '30px',
                                marginBottom: '5px',
                                fontWeight: '600',
                                textTransform: 'uppercase',
                                fontSize: '0.8em'
                            }}
                                className="divider">Apply Credits</div>
                            <IonItem>
                                <IonLabel>Available Credits ({availableCredits.length})</IonLabel>
                                <IonSelect
                                    value={numberOfCreditsToUse}
                                    onIonChange={e => setNumberOfCreditsToUse(e.detail.value)}
                                    interface="popover"
                                    placeholder="Select">
                                    {availableCredits.slice(0, numberOfNights).map((credit: any, index: number) => (
                                        <IonSelectOption key={index} value={index + 1}>{index + 1}</IonSelectOption>
                                    ))}
                                </IonSelect>


                            </IonItem>
                        </>
                    )}


                    <IonItem style={{ marginTop: '30px' }} lines="none">
                        <IonLabel>Nightly Subtotal</IonLabel>
                        <IonNote>${nightlySubtotal.toFixed(2)}</IonNote>
                    </IonItem>
                    {
                        addOnFee > 0 && (
                            <IonItem lines="none">
                                <IonLabel>Add-on Subtotal</IonLabel>
                                <IonNote>${addOnFee.toFixed(2)}</IonNote>
                            </IonItem>
                        )
                    }
                    {numberOfCreditsToUse > 0 && (
                        <IonItem lines="none">
                            <IonLabel>Credits Applied</IonLabel>
                            <IonNote>-${(numberOfCreditsToUse * 25).toFixed(2)}</IonNote>
                        </IonItem>
                    )}
                    {discountRate > 0 && (
                        <IonItem lines="none">
                            <IonLabel>Bulk Discount</IonLabel>
                            <IonNote>-${bulkDiscount.toFixed(2)}</IonNote>
                        </IonItem>
                    )}

                    <IonItem lines="none">
                        <IonLabel>Total</IonLabel>
                        <IonNote style={{ color: '#000' }}>${total.toFixed(2)}</IonNote>
                    </IonItem>
                    <IonButton
                        expand="block"
                        shape="round"
                        color="dark"
                        onClick={readyToCheckout}
                        disabled={gettingIntent}
                    >Checkout
                        {gettingIntent && (<IonSpinner className="ion-margin-start" color="light" />)}
                    </IonButton>
                    {/* <p>{discountText}</p> */}
                </>

            )}

            <IonToast
                isOpen={successToast}
                onDidDismiss={() => setSuccessToast(false)}
                message="Successfully booked your stay!"
                duration={5000}
                position="top"
                color="primary" />

            <IonToast
                isOpen={errorToast}
                onDidDismiss={() => setErrorToast(false)}
                message={errorMessage}
                duration={5000}
                position="top"
                color="danger"
                buttons={[
                    {
                        text: 'Close',
                        role: 'cancel',
                    },
                ]} />

        </>


    )

}

export default UpdateStayCart;