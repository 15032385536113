
import { IonButton, IonCheckbox, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonNote, IonRow, IonSelect, IonSelectOption, IonSpinner, IonToast } from "@ionic/react";
import { ReservationDetails } from "../models/reservationDetails.model";
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import './SiteDetail.css';

import 'swiper/css';
import 'swiper/css/pagination';
import '@ionic/react/css/ionic-swiper.css';
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useHistory } from "react-router";
import { Member } from "../models/member.model";
import useToastError from "../hooks/useToastError";
import StripeCheckout from "./StripeCheckout";
import { getAuth } from "firebase/auth";
import { getDoc, doc, getDocs, collection, query, where } from "firebase/firestore";
import { db } from "../config/firebase";
import { checkmarkOutline, closeOutline, pin } from "ionicons/icons";

interface PaymentDetails {
    numberOfNights: number;
    hasCopilot: boolean;
    hasPet: boolean;
    creditsToUse: number;

}

interface SiteDetailProps {
    site: any;
    reservationDetails: ReservationDetails;
    closeModal: () => void;
    setReservationDetails: (details: ReservationDetails) => void;
    member: Member;
}

const SiteDetail: React.FC<SiteDetailProps> = ({ site, reservationDetails, closeModal, setReservationDetails, member }) => {
    const displayError = useToastError();
    const history = useHistory()

    const [hasCopilot, setHasCopilot] = useState(false);
    const [hasPet, setHasPet] = useState(false);
    const [addOnFee, setAddOnFee] = useState(0);
    const [makingReservation, setMakingReservation] = useState(false);
    const [successToast, setSuccessToast] = useState(false);
    const [errorToast, setErrorToast] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [canBook, setCanBook] = useState(false);
    const [needsPayment, setNeedsPayment] = useState(false);
    // const [membershipExpires, setMembershipExpires] = useState(''); // Date
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [checkoutLocked, setCheckoutLocked] = useState(false);
    const [clientSecret, setClientSecret] = useState("");
    const [gettingIntent, setGettingIntent] = useState(false);
    const [discountRate, setDiscountRate] = useState(0);
    const [discountText, setDiscountText] = useState('');
    const [availableCredits, setAvailableCredits] = useState<any[]>([]);
    const [numberOfCreditsToUse, setNumberOfCreditsToUse] = useState(0);
    const [nightlySubtotal, setNightlySubtotal] = useState(0);
    const [bulkDiscount, setBulkDiscount] = useState(0);
    const [creditsDiscount, setCreditsDiscount] = useState(0);
    const [total, setTotal] = useState(0);



    useEffect(() => {
        getMemberInfo();
        // getMemberBookingAccess();
    }, [])

    useEffect(() => {
        if (!member.ACTIVE_PASS) {
            setNeedsPayment(true);
            getCredits();
        } else {
            setNeedsPayment(false);
        }

        if (member.ACTIVE_MEMBERSHIP) {
            setCanBook(true);
        } else {
            setCanBook(false);
        }
    }, [member])


    // calculate all the subtotals
    useEffect(() => {
        if (reservationDetails && reservationDetails.numberOfNights) {
            const n = reservationDetails.numberOfNights;
            const effectiveN = n - numberOfCreditsToUse;
            setNightlySubtotal(n * 25)

            setAddOnFee((hasCopilot ? (n * 10) : 0) + (hasPet ? (n * 1) : 0));      // add on fees are calculated for the entire stay, not the effective nights with credits

            // the discount rate is based on the effective nights with credits
            if (effectiveN < 10) {
                setDiscountRate(0);
                setDiscountText(`Book 10 nights or more to get 10% off`);
            } else if (effectiveN < 20) {
                setDiscountRate(0.1);
                setDiscountText(`You've unlocked a 10% discount. Book 20 nights or more to get 20% off`);
            } else {
                setDiscountRate(0.2);
                setDiscountText(`You've unlocked a 20% discount!`);
            }

            setBulkDiscount((effectiveN * 25) * discountRate)
            setCreditsDiscount(numberOfCreditsToUse * 25)

        }
    }, [reservationDetails, numberOfCreditsToUse, hasCopilot, hasPet, discountRate])

    // calculate the total
    useEffect(() => {
        setTotal(nightlySubtotal + addOnFee - creditsDiscount - bulkDiscount)
    }, [nightlySubtotal, addOnFee, creditsDiscount, bulkDiscount])

    const getMemberInfo = async () => {
        const auth = getAuth();
        const uid = auth.currentUser!.uid;
        const member = await getDoc(doc(db, 'MEMBERS', uid));
        const { FIRST_NAME, LAST_NAME, EMAIL } = member.data() as Member;
        setFirstName(FIRST_NAME);
        setLastName(LAST_NAME);
        setEmail(EMAIL);
    }

    const getCredits = async () => {
        const auth = getAuth();
        const uid = auth.currentUser!.uid;
        const querySnapshot = await getDocs(query(collection(db, 'CREDITS'),
            where('MEMBER_ID', '==', uid),
            where('REDEEMED', '==', false)));
        setAvailableCredits(querySnapshot.docs.map(doc => doc.data()));
    }


    const canMakeReservation = (): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            const params = {
                startDate: reservationDetails.startDate,
                endDate: reservationDetails.endDate,
                resourceId: reservationDetails.locationId,
                email,
                firstName,
                lastName
            }
            const functions = getFunctions();
            httpsCallable(functions, 'planyo-canMakeReservation')(params)
                .then((result: any) => {
                    const { data } = result;
                    if (data.error) {
                        if (data.reason) {
                            setErrorMessage(data.reason);
                            setErrorToast(true);
                            reject(false)
                        } else {
                            throw new Error(data.error)
                        }
                    }
                    else {
                        if (data.canReserve) {
                            resolve(true);
                        } else {
                            reject(false);
                        }
                    }
                })
        })
    }

    const cleanupAfterSuccess = () => {
        setMakingReservation(false);
        setSuccessToast(true);
        closeModal();
        history.push('/stays?refresh=true');
        setReservationDetails({
            startDate: '',
            endDate: '',
            numberOfNights: 0,
            locationId: -1,
            locationName: null,
            hasSearched: false,
        })
    }

    const makeActivePassReservation = async () => {
        try {
            await makeReservation()
            cleanupAfterSuccess();
        } catch (error: any) {
            console.error(error);
            setErrorMessage(error);
            setErrorToast(true);
            setMakingReservation(false);
        }
    }


    const makeReservation = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            setMakingReservation(true);
            if (!firstName || !lastName || !email) {
                displayError({ message: 'Missing name and email to book your stay' })
                setMakingReservation(false);
                reject('Missing name and email to book your stay')
            }
            // TODO I don't think we need this yet.
            // if (isAfter(new Date(membershipExpires), new Date(reservationDetails.endDate!))) {
            //     displayError({ message: `Your membership expires ${membershipExpires} before the end your stay` })
            //     setMakingReservation(false);
            //     reject(`Membership expires before end of stay`)
            // }

            // TODO add the MEMBERSHIP_TYPE to the reservation
            const params = {
                startDate: reservationDetails.startDate,
                endDate: reservationDetails.endDate,
                resourceId: reservationDetails.locationId,
                resourceName: reservationDetails.locationName,
                email,
                firstName,
                lastName,
                creditsUsed: numberOfCreditsToUse,
                membershipType: member.ACTIVE_PASS ? 'pass' : 'nightly'
            }

            console.log('Making Booking with params: ', params)
            const functions = getFunctions();
            httpsCallable(functions, 'planyo-makeReservation')(params)
                .then((result: any) => {
                    const { data } = result;
                    if (data.error) {
                        setMakingReservation(false);
                        if (data.response_message) {
                            setErrorMessage(data.response_message);
                            setErrorToast(true);
                            reject(data.response_message)
                        } else {

                            reject(data.error)
                            throw new Error(data.error)
                        }
                    }
                    else {
                        resolve(data)
                    }
                })
        })
    }

    const getStripeIntent = (stripeCustomerId: string) => {
        const functions = getFunctions();
        const paymentDetails: PaymentDetails = {
            numberOfNights: reservationDetails.numberOfNights,
            hasCopilot,
            hasPet,
            creditsToUse: numberOfCreditsToUse
        }

        return httpsCallable(functions, 'stripe-createPaymentIntent')({
            paymentDetails,
            customerId: stripeCustomerId,
        })
            .then((result: any) => {
                const { data, error } = result;
                if (error) {
                    throw new Error(error)
                } else {
                    setClientSecret(data.clientSecret);
                }
            })
            .catch(e => {
                console.error(e)
                displayError({ message: 'Error initiating payment. Please email support@kift.com' });
            })
            .finally(() => { });
    }

    const readyToCheckout = async () => {
        setGettingIntent(true);
        // get customer stripe id
        const auth = getAuth();
        const uid = auth.currentUser!.uid;
        const member = await getDoc(doc(db, 'MEMBERS', uid));
        const { STRIPE_ID } = member.data() as Member;

        // check to make sure payment amount isnt 0 (in the case of using credits)
        if (total === 0) {
            setGettingIntent(false)
            makeReservation().then(() => {
                cleanupAfterSuccess()
            }, err => {
                console.error(err)
                displayError({ message: 'Error making reservation. Please email support@kift.com ' })
            });
        } else {
            // create payment intent
            getStripeIntent(STRIPE_ID).then(() => {
                setCheckoutLocked(true)
            }, err => {
                console.error(err)
                displayError({ message: 'Error initiating payment. Please email support@kift.com' })
            }).finally(() => setGettingIntent(false))
        }


    }

    return (
        <>
            <IonGrid className="ion-no-padding">
                <IonRow className="ion-no-padding">
                    <IonCol size="12" className="ion-no-padding">
                        <h1 className="ion-padding">{site.name}</h1>
                        <div className="carousel-wrapper">
                            <Swiper
                                pagination={{
                                    clickable: true
                                }}
                                modules={[Pagination]}
                                className="mySwiper">
                                {site.photos.map((photo: any) => (
                                    <SwiperSlide key={photo.id} style={{ height: '300px' }}>
                                        <img
                                            style={{ width: '100%', height: '100%' }}
                                            src={photo.path}
                                            alt="Site"></img>
                                    </SwiperSlide>
                                )
                                )}
                            </Swiper>
                        </div>
                        <IonItem lines="none">
                            <IonIcon color="medium" icon={pin} slot="start"></IonIcon>
                            <IonLabel style={{ color: 'var(--ion-color-medium-shade)' }}>{site.properties.location}</IonLabel>
                        </IonItem>



                        {reservationDetails && reservationDetails.hasSearched && (
                            <div className="reservation-wrapper">

                                {!checkoutLocked && (
                                    <>
                                        <IonGrid>
                                            <IonRow>
                                                <IonCol size="9">
                                                    <div id="header">Book your {reservationDetails.numberOfNights} night stay</div>
                                                </IonCol>
                                                <IonCol size="3">
                                                    <IonButton
                                                        style={{ float: 'right' }}
                                                        size="small"
                                                        fill="outline"
                                                        color="dark"
                                                        onClick={() => { closeModal(); setCheckoutLocked(false) }}>
                                                        Edit Nights</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>


                                        <IonItem lines="none">
                                            <IonLabel>Start Date</IonLabel>
                                            <IonNote>{format(new Date(reservationDetails.startDate!.replace(/-/g, '/')), 'MMM dd, yyyy')}</IonNote>
                                        </IonItem>
                                        <IonItem lines="none">
                                            <IonLabel>End Date</IonLabel>
                                            <IonNote>{format(new Date(reservationDetails.endDate!.replace(/-/g, '/')), 'MMM dd, yyyy')}</IonNote>
                                        </IonItem>

                                        {needsPayment ? (
                                            <>
                                                <div style={{
                                                    marginTop: '30px',
                                                    marginBottom: '5px',
                                                    fontWeight: '600',
                                                    textTransform: 'uppercase',
                                                    fontSize: '0.8em'
                                                }}
                                                    className="divider">Add-ons</div>
                                                <IonItem lines="none">
                                                    <IonCheckbox disabled={checkoutLocked} slot="start" labelPlacement="end" onIonChange={() => setHasCopilot(!hasCopilot)}>Include a co-pilot</IonCheckbox>
                                                    <IonNote slot="end">+$10/NIGHT</IonNote>
                                                </IonItem>
                                                <IonItem lines="none">
                                                    <IonCheckbox disabled={checkoutLocked} slot="start" labelPlacement="end" onIonChange={() => setHasPet(!hasPet)}>Include a pet</IonCheckbox>
                                                    <IonNote slot="end">+$1/NIGHT</IonNote>
                                                </IonItem>

                                                {availableCredits.length > 0 && (
                                                    <>
                                                        <div style={{
                                                            marginTop: '30px',
                                                            marginBottom: '5px',
                                                            fontWeight: '600',
                                                            textTransform: 'uppercase',
                                                            fontSize: '0.8em'
                                                        }}
                                                            className="divider">Apply Credits</div>
                                                        <IonItem>
                                                            <IonLabel>Available Credits ({availableCredits.length})</IonLabel>
                                                            <IonSelect
                                                                value={numberOfCreditsToUse}
                                                                onIonChange={e => setNumberOfCreditsToUse(e.detail.value)}
                                                                interface="popover"
                                                                placeholder="Select">
                                                                {availableCredits.slice(0, reservationDetails.numberOfNights).map((credit: any, index: number) => (
                                                                    <IonSelectOption key={index} value={index + 1}>{index + 1}</IonSelectOption>
                                                                ))}
                                                            </IonSelect>


                                                        </IonItem>
                                                    </>
                                                )}


                                                <IonItem style={{ marginTop: '30px' }} lines="none">
                                                    <IonLabel>Nightly Subtotal</IonLabel>
                                                    <IonNote>${nightlySubtotal.toFixed(2)}</IonNote>
                                                </IonItem>
                                                {
                                                    addOnFee > 0 && (
                                                        <IonItem lines="none">
                                                            <IonLabel>Add-on Subtotal</IonLabel>
                                                            <IonNote>${addOnFee.toFixed(2)}</IonNote>
                                                        </IonItem>
                                                    )
                                                }
                                                {numberOfCreditsToUse > 0 && (
                                                    <IonItem lines="none">
                                                        <IonLabel>Credits Applied</IonLabel>
                                                        <IonNote>-${(numberOfCreditsToUse * 25).toFixed(2)}</IonNote>
                                                    </IonItem>
                                                )}
                                                {discountRate > 0 && (
                                                    <IonItem lines="none">
                                                        <IonLabel>Bulk Discount</IonLabel>
                                                        <IonNote>-${bulkDiscount.toFixed(2)}</IonNote>
                                                    </IonItem>
                                                )}

                                                <IonItem lines="none">
                                                    <IonLabel>Total</IonLabel>
                                                    <IonNote style={{ color: '#000' }}>${total.toFixed(2)}</IonNote>
                                                </IonItem>
                                                <p>{discountText}</p>
                                            </>
                                        ) : (
                                            // TODO improve this UI
                                            // TODO check to make sure the reservation end date is before the membership pass ends
                                            <>
                                                <p> Your membership pass includes unlimited bookings during this period 🎉 </p>
                                            </>
                                        )}

                                    </>
                                )}

                                {canBook && needsPayment && (
                                    <>
                                        {!checkoutLocked ? (
                                            <IonButton expand="block" color="dark" shape="round" onClick={readyToCheckout}>
                                                <IonLabel>Checkout</IonLabel>
                                                {gettingIntent && <IonSpinner slot="end" color="light" style={{ marginLeft: '20px' }}></IonSpinner>}
                                            </IonButton>
                                        ) : (
                                            <>
                                                <IonButton
                                                    onClick={() => setCheckoutLocked(false)}
                                                    expand="block"
                                                    shape="round"
                                                    color="danger"
                                                    style={{ marginTop: '30px', marginBottom: '30px' }}> Change Reservation
                                                </IonButton>
                                                <StripeCheckout
                                                    clientSecret={clientSecret}
                                                    email={email}
                                                    makeReservation={makeReservation}
                                                    canMakeReservation={canMakeReservation}
                                                    doneCallback={cleanupAfterSuccess} />
                                            </>
                                        )}
                                    </>
                                )}

                                {/* active pass booking */}
                                {canBook && !needsPayment && (
                                    <IonButton
                                        disabled={makingReservation}
                                        expand="block"
                                        color="dark" shape="round" onClick={makeActivePassReservation}>Book {site.name}
                                        {makingReservation && (<IonSpinner color="light" style={{ marginLeft: '20px' }}></IonSpinner>)}

                                    </IonButton>
                                )}

                                <IonToast
                                    isOpen={successToast}
                                    onDidDismiss={() => setSuccessToast(false)}
                                    message="Successfully booked your stay!"
                                    duration={5000}
                                    position="top"
                                    color="primary" />

                                <IonToast
                                    isOpen={errorToast}
                                    onDidDismiss={() => setErrorToast(false)}
                                    message={errorMessage}
                                    duration={5000}
                                    position="top"
                                    color="danger"
                                    buttons={[
                                        {
                                            text: 'Close',
                                            role: 'cancel',
                                        },
                                    ]} />

                            </div>


                        )}


                        <div className="ion-padding" style={{ paddingTop: 0 }}>
                            <p>{site.properties.description}</p>

                            <div className="amenities-header">
                                Amenities
                            </div>
                            <div className="amenities">
                                <IonItem lines="none">
                                    {site.properties.stocked_pantry === "yes" ? <IonIcon slot="start" icon={checkmarkOutline} /> : <IonIcon slot="start" icon={closeOutline} />}
                                    <IonLabel>Stocked Pantry</IonLabel>
                                </IonItem>
                                {site.properties.partner_site === "yes" && (
                                    <IonItem lines="none">
                                        <IonIcon slot="start" icon={checkmarkOutline}></IonIcon>
                                        <IonLabel>Partner Site</IonLabel>
                                    </IonItem>
                                )}
                                {site.properties.conference_rooms && site.properties.conference_rooms !== "none" && (
                                    <IonItem lines="none">
                                        <IonIcon slot="start" icon={checkmarkOutline}></IonIcon>
                                        <IonLabel>Conference Rooms ({site.properties.conference_rooms})</IonLabel>
                                    </IonItem>
                                )}
                                {site.properties.electrical_hookup === "yes" && (
                                    <IonItem lines="none">
                                        <IonIcon slot="start" icon={checkmarkOutline}></IonIcon>
                                        <IonLabel>
                                            <div>Electrical Hookup</div>
                                            <p>Ask the site liason before use</p>
                                        </IonLabel>
                                    </IonItem>
                                )}
                                {site.properties.hiking === "yes" && (
                                    <IonItem lines="none">
                                        <IonIcon slot="start" icon={checkmarkOutline}></IonIcon>
                                        <IonLabel>Hiking</IonLabel>
                                    </IonItem>
                                )}
                                {site.properties.national_park === "yes" && (
                                    <IonItem lines="none">
                                        <IonIcon slot="start" icon={checkmarkOutline}></IonIcon>
                                        <IonLabel>National Park nearby</IonLabel>
                                    </IonItem>
                                )}

                                {site.properties.hot_tub === "yes" && (
                                    <IonItem lines="none">
                                        <IonIcon slot="start" icon={checkmarkOutline}></IonIcon>
                                        <IonLabel>Hot tub</IonLabel>
                                    </IonItem>
                                )}
                                {site.properties.fire_pit === "yes" && (
                                    <IonItem lines="none">
                                        <IonIcon slot="start" icon={checkmarkOutline}></IonIcon>
                                        <IonLabel>Fire Pit</IonLabel>
                                    </IonItem>
                                )}
                                {site.properties.outdoor_shower === "yes" && (
                                    <IonItem lines="none">
                                        <IonIcon slot="start" icon={checkmarkOutline}></IonIcon>
                                        <IonLabel>Outdoor Showers</IonLabel>
                                    </IonItem>
                                )}

                                {site.properties.steam_shower === "yes" && (
                                    <IonItem lines="none">
                                        <IonIcon slot="start" icon={checkmarkOutline}></IonIcon>
                                        <IonLabel>Steam Shower</IonLabel>
                                    </IonItem>
                                )}
                                {site.properties.sauna === "yes" && (
                                    <IonItem lines="none">
                                        <IonIcon slot="start" icon={checkmarkOutline}></IonIcon>
                                        <IonLabel>Sauna</IonLabel>
                                    </IonItem>
                                )}
                                {site.properties.hammocks === "yes" && (
                                    <IonItem lines="none">
                                        <IonIcon slot="start" icon={checkmarkOutline}></IonIcon>
                                        <IonLabel>Hammocks</IonLabel>
                                    </IonItem>
                                )}
                                {site.properties.cold_plunge === "yes" && (
                                    <IonItem lines="none">
                                        <IonIcon slot="start" icon={checkmarkOutline}></IonIcon>
                                        <IonLabel>Cold Plunge</IonLabel>
                                    </IonItem>
                                )}
                                {site.properties.paddleboarding === "yes" && (
                                    <IonItem lines="none">
                                        <IonIcon slot="start" icon={checkmarkOutline}></IonIcon>
                                        <IonLabel>Paddleboarding</IonLabel>
                                    </IonItem>
                                )}
                                {site.properties.canoeing === "yes" && (
                                    <IonItem lines="none">
                                        <IonIcon slot="start" icon={checkmarkOutline}></IonIcon>
                                        <IonLabel>Canoeing</IonLabel>
                                    </IonItem>
                                )}
                                {site.properties.kayaking === "yes" && (
                                    <IonItem lines="none">
                                        <IonIcon slot="start" icon={checkmarkOutline}></IonIcon>
                                        <IonLabel>Kayaking</IonLabel>
                                    </IonItem>
                                )}

                            </div>

                            {/* <IonButton onClick={closeModal}>Close</IonButton> */}
                        </div>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </>
    )
}

export default SiteDetail;