import { BrowserRouter, Route } from 'react-router-dom';
import {
  IonApp,
  setupIonicReact,
  isPlatform,
  IonPage,
  IonContent,
  IonButton,
} from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/global.css';
import MemberApp from './MemberApp';
import { ErrorBoundary } from "react-error-boundary";
import { db } from './config/firebase';
import { addDoc, collection } from 'firebase/firestore';

setupIonicReact({
  mode: isPlatform('android') ? 'md' : 'ios',
});

const Fallback = (props: any) => {
  const { error, resetErrorBoundary } = props;
  return (
    <IonPage>
      <IonContent className="ion-padding"  style={{textAlign: 'center'}}>
        <p>Something went wrong...</p>
        <p>Please try again or email <a style={{ color: 'var(--ion-color-dark' }} href="mailto:support@kift.com">support</a></p>
        <p>{error.message}</p>

        <IonButton expand="block" color="dark" shape="round" onClick={resetErrorBoundary} > Try Again </IonButton>
      </IonContent>
    </IonPage>
  );
}

const logError = async (error: Error, info: any) => {

  const { message, stack } = error;

  console.log(error, info);
  const docRef = await addDoc(collection(db, 'ERRORS'), {
    message,
    stack,
    info: info ? info.componentStack : null
  })
  console.log('Error logged: ', docRef.id)
}


const App: React.FC = () => {

  return (
    <IonApp>
      <ErrorBoundary FallbackComponent={Fallback} onError={logError}>
        <BrowserRouter>
          <Route path="/">
            <MemberApp />
          </Route>
        </BrowserRouter>
      </ErrorBoundary>
    </IonApp>
  )
};

export default App;
