import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, isPlatform } from "@ionic/react";
import { Route, Redirect } from "react-router";


import { IonReactRouter } from "@ionic/react-router";
import { triangle, ellipse, square } from "ionicons/icons";
import HomePage from "./pages/HomePage";
import StaysPage from "./pages/StaysPage";
import ProfilePage from "./pages/ProfilePage";
import LoginPage from "./pages/LoginPage";
import { useEffect, useState } from "react";
import { User } from "firebase/auth";
import { auth, db } from "./config/firebase";
import { doc, getDoc } from "firebase/firestore";
import { QueryClient, QueryClientProvider } from 'react-query'

interface MemberAppProps { }

const MemberApp: React.FC<MemberAppProps> = () => {
    const [user, setUser] = useState<User | null>(null);
    const [member, setMember] = useState<any>(null);

    const queryClient = new QueryClient()

    useEffect(() => {
        auth().onAuthStateChanged(async (user) => {
            if (user) {
                const result = await getDoc(doc(db, 'MEMBERS', user.uid));
                if (result.exists()) {
                    console.warn('Member at app level: ', result.data())
                    setMember(result.data());
                    setUser(user);
                }
            } else {
                setUser(null);
                setMember(null)
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <IonReactRouter>
            {member ? (
                <QueryClientProvider client={queryClient}>
                <IonTabs>
                    <IonRouterOutlet>
                        <Route exact path="/home">
                            <HomePage member={member}/>
                        </Route>
                        <Route exact path="/stays">
                            <StaysPage member={member} />
                        </Route>
                        <Route path="/profile">
                            <ProfilePage />
                        </Route>
                        <Redirect to="/home" />
                    </IonRouterOutlet>
                    <IonTabBar color="warning" slot={isPlatform('mobile') ? 'bottom' : 'top'}>
                        <IonTabButton tab="home" href="/home">
                            <IonIcon aria-hidden="true" icon={triangle} />
                            <IonLabel>Book</IonLabel>
                        </IonTabButton>
                        <IonTabButton tab="stays" href="/stays">
                            <IonIcon aria-hidden="true" icon={square} />
                            <IonLabel>Stays</IonLabel>
                        </IonTabButton>
                        <IonTabButton tab="profile" href="/profile">
                            <IonIcon aria-hidden="true" icon={ellipse} />
                            <IonLabel>Profile</IonLabel>
                        </IonTabButton>
                    </IonTabBar>
                </IonTabs>
                </QueryClientProvider>
            ) : (
                <IonRouterOutlet>
                    <Route path="/login">
                        <LoginPage user={user} setUser={setUser} member={member} setMember={setMember} />
                    </Route>

                    <Redirect to="/login" />

                </IonRouterOutlet>
            )}

        </IonReactRouter>
    );
};

export default MemberApp;
