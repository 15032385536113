import { IonButton, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonItem, IonLabel, IonNote, IonPage, IonRow, IonSpinner, IonTitle, IonToolbar } from '@ionic/react';
import { getAuth, signOut } from "firebase/auth";
// import { useEffect } from 'react';

import './ProfilePage.css';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { db } from '../config/firebase';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { Member, MemberBookingAccess } from '../models/member.model';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import useToastError from '../hooks/useToastError';

const bookingAccessDefaults: MemberBookingAccess = {
  canBook: false,
  needsPayment: false,
  passExpires: null,
  membershipExpires: null
}

const ProfilePage: React.FC = () => {
  const history = useHistory();
  const displayError = useToastError();
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [hubspotId, setHubspotId] = useState<string>("");
  const [planyoId, setPlanyoId] = useState<string>("");
  const [stripeId, setStripeId] = useState<string>("");
  const [memberId, setMemberId] = useState<string>("");
  const [updating, setUpdating] = useState<boolean>(false);
  // const [credits, setCredits] = useState<any[]>([]);
  const [availableCredits, setAvailableCredits] = useState<any[]>([]);
  const [fetchingCredits, setFetchingCredits] = useState<boolean>(false);
  const [bookingAccess, setBookingAccess] = useState<MemberBookingAccess>(bookingAccessDefaults);


  useEffect(() => {
    getMemberInfo();
    getCredits();
    getMemberBookingAccess();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const logout = () => {
    const auth = getAuth();
    signOut(auth);
    history.push('/');
  }

  const getMemberInfo = async () => {
    const auth = getAuth();
    const uid = auth.currentUser!.uid;
    const member = await getDoc(doc(db, 'MEMBERS', uid));
    const { FIRST_NAME, LAST_NAME, EMAIL, HUBSPOT_ID, PLANYO_ID, STRIPE_ID, _UID } = member.data() as Member;
    setFirstName(FIRST_NAME);
    setLastName(LAST_NAME);
    setEmail(EMAIL);
    setHubspotId(HUBSPOT_ID);
    setPlanyoId(PLANYO_ID);
    setStripeId(STRIPE_ID);
    setMemberId(_UID)
  }

  const getCredits = async () => {
    setFetchingCredits(true);
    const auth = getAuth();
    const uid = auth.currentUser!.uid;
    const querySnapshot = await getDocs(query(collection(db, 'CREDITS'), where('MEMBER_ID', '==', uid)));
    // setCredits(querySnapshot.docs.map(doc => doc.data()));
    setAvailableCredits(querySnapshot.docs.map(doc => doc.data()).filter(credit => credit.REDEEMED === false));
    setFetchingCredits(false);
  }

  const updateUserInfo = async () => {
    setUpdating(true);
    const auth = getAuth();
    const functions = getFunctions()
    const uid = auth.currentUser!.uid;
    httpsCallable(functions, 'member-setupMember')({ uid, email, firstName, lastName })
      .then(() => getMemberInfo())
      .catch((error) => {
        console.log(error);
      }
      ).finally(() => {
        setUpdating(false);
      });
  }

  const getMemberBookingAccess = () => {
    const functions = getFunctions();
    return httpsCallable(functions, 'hubspot-bookingAccess')()
      .then((result: any) => {
        console.warn('Member Booking Access: ', result)
        const { data, error } = result;
        if (error) {
          displayError({ message: 'Error checking booking access' })
          throw new Error(error)
        } else {
          if (data.error) {
            console.error('Error: ', data.error)
            displayError({ message: data.error })
          }
          setBookingAccess({
            canBook: data.canBook,
            needsPayment: data.needsPayment,
            passExpires: data.passExpires,
            membershipExpires: data.membershipExpires
          })
        }
      })
  }

  // const testTimeout = async () => {
  //   const functions = getFunctions();
  //   const testTimeout = httpsCallable(functions, 'planyo-testTimeout');
  //   const result: any = await testTimeout();
  //   console.warn('result: ', result)
  // }

  return (
    <IonPage>
      <IonHeader color="secondary">
        <IonToolbar>
          <IonTitle>Profile</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Profile</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div>
          <IonGrid className="profile-wrapper">
            {/* <IonButton onClick={() => testTimeout()}>Test Timeout</IonButton> */}
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel>First Name</IonLabel>
                  <IonNote>{firstName}</IonNote>
                </IonItem>
                <IonItem>
                  <IonLabel>Last Name</IonLabel>
                  <IonNote>{lastName}</IonNote>
                </IonItem>
                <IonItem>
                  <IonLabel>Email</IonLabel>
                  <IonNote>{email}</IonNote>
                </IonItem>
                <IonItem>
                  <IonLabel>Membership Status</IonLabel>
                  {bookingAccess.canBook ? <IonChip outline={true} color="success" slot="end">Active</IonChip> : <IonChip outline={true} color="danger" slot="end">Inactive</IonChip>}
                </IonItem>
                {bookingAccess.canBook && (
                  <IonItem>
                    <IonLabel>Booking Access</IonLabel>
                    {bookingAccess.needsPayment ? <IonChip color="primary" outline={true} slot="end">Nightly</IonChip> : <IonChip outline={true} color="primary" slot="end">Unlimited</IonChip>}
                  </IonItem>
                )
                }


                <IonItem>
                  <IonLabel>Credits</IonLabel>
                  <IonNote>{availableCredits.length}</IonNote>
                </IonItem>
                <IonButton shape="round" style={{ marginTop: '20px' }} expand="block" color="primary" onClick={() => { updateUserInfo(); getMemberBookingAccess() }}>
                  Refresh Membership Info
                  {updating && <IonSpinner slot="end" />}
                </IonButton>

                <IonButton shape="round" style={{ marginTop: '20px' }} expand="block" color="primary" onClick={getCredits}>
                  Refresh Credits
                  {fetchingCredits && <IonSpinner slot="end" />}
                </IonButton>

                <IonButton shape="round" style={{ marginTop: '20px' }} expand="block" color="danger" onClick={logout}>
                  Logout
                </IonButton>

                <div style={{ height: '100vh' }}></div>
                <div style={{ marginTop: '50px' }}>For Debugging:</div>
                <IonItem>
                  <IonLabel>Hubspot ID</IonLabel>
                  <IonNote>{hubspotId}</IonNote>
                </IonItem>
                <IonItem>
                  <IonLabel>Planyo ID</IonLabel>
                  <IonNote>{planyoId}</IonNote>
                </IonItem>
                <IonItem>
                  <IonLabel>Stripe ID</IonLabel>
                  <IonNote>{stripeId}</IonNote>
                </IonItem>
                <IonItem onClick={() => { navigator.clipboard.writeText(memberId) }}>
                  <IonLabel>ID</IonLabel>
                  <IonNote>{memberId}</IonNote>
                </IonItem>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ProfilePage;
