import { IonButton, IonCol, IonContent, IonGrid, IonHeader, IonPage, IonRefresher, IonRefresherContent, IonRow, IonTitle, IonToolbar, RefresherEventDetail } from '@ionic/react';
import BookingSearch from '../components/BookingSearch';
import { useEffect, useRef, useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import SiteCard from '../components/SiteCard';
import { ReservationDetails } from '../models/reservationDetails.model';
import { useHistory } from 'react-router';
import useToastError from '../hooks/useToastError';
import { Member } from '../models/member.model';

import './HomePage.css';

const reservationDefaults: ReservationDetails = {
  startDate: null,
  endDate: null,
  locationId: -1,
  locationName: null,
  hasSearched: false,
  numberOfNights: 0
}

interface HomePageProps {
  member: Member;
}

const HomePage: React.FC<HomePageProps> = ({member}) => {
  const displayError = useToastError();
  const history = useHistory();
  const page = useRef(null);
  const [presentingElement, setPresentingElement] = useState<HTMLElement | null>(null);
  const [resources, setResources] = useState<any[]>([]);
  const [filteredResources, setFilteredResources] = useState<any[]>([]);
  const [fetchingResources, setFetchingResources] = useState<boolean>(false);
  const [searchingResources, setSearchingResources] = useState<boolean>(false);
  const [reservationDetails, setReservationDetails] = useState<ReservationDetails>(reservationDefaults);
  const [initLoad, setInitLoad] = useState<boolean>(false);

  const searchResources = (startDate: string, endDate: string, locationId: number, numberOfNights: number) => {
    if (startDate && endDate && locationId) {
      setSearchingResources(true);
      const functions = getFunctions();

      const params: any = {
        startDate,
        endDate,
      }

      if (locationId !== -1) {
        params['resourceId'] = locationId;
      }

      httpsCallable(functions, 'planyo-resourceSearch')(params)
        .then((result: any) => {
          console.warn('Resource Search Result: ', result)
          const { data, error } = result;
          if (error) {
            throw new Error(error)
          } else {
            // data: {reason_not_listed: {code: message}, results: Site[]}
            if (data.results) {
              if(!Array.isArray(data.results)) { 
                data.results = Object.values(data.results)
              }
              const ids = data.results.map((x: any) => parseInt(x.id))
              const filtered = resources.filter(x => ids.includes(x.id))
              setFilteredResources(filtered);
              setReservationDetails({
                startDate,
                endDate,
                locationId,
                locationName: locationId === -1 ? 'All Locations' : filtered[0].name,
                hasSearched: true,
                numberOfNights,
              })
            }

            if (!data.results && Object.values(data.reason_not_listed).length > 0) {
              const message = Object.values(data.reason_not_listed)[0] as string;
              displayError({ message })
            }

          }
        }).finally(() =>setSearchingResources(false));
    }
  }

  const resetResources = () => {
    setFilteredResources(resources);
  }

  const getResources = () => {
    setFetchingResources(true);
    const functions = getFunctions();
    httpsCallable(functions, 'planyo-listResources')()
      .then((result: any) => {
        console.warn('Resource Fetch Result: ', result)
        const { data, error } = result;
        if (error) {
          throw new Error(error)
        } else {
          setResources(data);
          setFilteredResources(data);
        }
      }).finally(() => {
        setFetchingResources(false);
        setInitLoad(true);
      });
  }

  // const checkMemberBookingAccess = () => {
  //   setFetchingPlan(true)
  //   const functions = getFunctions();
  //   return httpsCallable(functions, 'hubspot-bookingAccess')()
  //     .then((result: any) => {
  //       console.warn('Member Booking Access: ', result)
  //       const { data, error } = result;
  //       if (error) {
  //         displayError({message: 'Error checking booking access'})
  //         throw new Error(error)
  //       } else {
  //         if (data.error) {
  //           console.error('Error: ', data.error)
  //           displayError({message: data.error})
  //         }
  //         setBookingAccess({
  //           canBook: data.canBook,
  //           needsPayment: data.needsPayment,
  //           passExpires: data.passExpires,
  //           membershipExpires: data.membershipExpires
  //         })
  //         if (data.canBook) {
  //           console.log('getting resources')
  //           setCanBook(true);
  //           getResources();
  //         } else {
  //           setCanBook(false)
  //         }
  //       }
  //     }).finally(() => {
  //       setInitLoad(true);
  //       setFetchingPlan(false);
  //     });
  // }

  const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    getResources();
    setTimeout(() => event.detail.complete(), 2000)
    
  }

  // const getMember = async () => {
  //   const auth = getAuth();
  //   const uid = auth.currentUser!.uid;
  //   const member = await getDoc(doc(db, 'MEMBERS', uid));
  //   console.warn('Member: ', member.data());
  //   setMember(member.data() as Member);
  // }

  // TODO @Brad, I would put the logic for fetching the members access in here but I need to be callable by the pull to refresh
  // so I put it in a function, but now I'm getting this warning (and the app won't build with prod flags because of the warning)
  useEffect(() => {
    setPresentingElement(page.current);
    getResources();
    // getMember()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   if (member) {
  //     if (member.HUBSPOT_ID) {
  //       checkMemberBookingAccess();
  //     } else {
  //       setInitLoad(true);
  //     }

  //   }
  // }, [member]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <IonPage ref={page}>
      <IonHeader>
        <IonToolbar color="secondary">
          <IonTitle>Booking</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Booking</IonTitle>
          </IonToolbar>
        </IonHeader>

        {initLoad && member && member.ACTIVE_MEMBERSHIP ? (
          <>
            <BookingSearch
              parentPage={presentingElement}
              resources={resources}
              searchResources={searchResources}
              fetchingResources={fetchingResources}
              searchingResources={searchingResources}
              reservationDetails={reservationDetails}
              setReservationDetails={setReservationDetails}
              resetResources={resetResources}
            ></BookingSearch>

            <IonGrid style={{ maxWidth: '1000px', margin: '0 auto' }}>
              <IonRow>
                {filteredResources.map((site: any) => (
                  <IonCol size="12" sizeLg="4" key={site.id}>
                    <SiteCard
                      site={site}
                      parentPage={presentingElement}
                      reservationDetails={reservationDetails}
                      setReservationDetails={setReservationDetails}
                      member={member} />
                  </IonCol>

                ))}
              </IonRow>
            </IonGrid>

          </>
        ) : (
          <>
            {initLoad && (
              <div className="ion-padding">
                <h2>You don't have an active Kift Membership.</h2>
                <div>Please subscribe in order to book</div>
                <h3>Think this message is an error?</h3>
                <div>Head to your profile page, refresh your membership data</div>
                <IonButton
                  style={{ marginTop: '20px' }}
                  expand="block"
                  shape="round"
                  color="dark"
                  onClick={() => history.push('/tab3')}>
                  Profile</IonButton>
              </div>
            )}

          </>


        )}

      </IonContent>

      {/* <IonLoading isOpen={fetchingPlan} duration={30000} /> */}
    </IonPage>
  );
};

export default HomePage;
