import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import { close } from "ionicons/icons";
import './SiteCard.css';
import { ReservationDetails } from "../models/reservationDetails.model";
import { useState } from "react";
import SiteDetail from "./SiteDetail";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';
import '@ionic/react/css/ionic-swiper.css';
import { Member } from "../models/member.model";
interface SiteCardProps {
    parentPage: HTMLElement | null;
    site: any;
    reservationDetails: ReservationDetails;
    setReservationDetails: (details: ReservationDetails) => void;
    member: Member;
}

const SiteCard = ({ site, reservationDetails, parentPage, setReservationDetails, member }: SiteCardProps) => {

    const [modalOpen, setModalOpen] = useState(false);

    return (
        <>
            <IonCard>
                <Swiper
                    pagination={{
                        clickable: true
                    }}
                    modules={[Pagination]}
                    className="mySwiper">
                    {site.photos.map((photo: any) => (
                        <SwiperSlide key={photo.id} style={{ height: '200px' }}>
                            <img
                                style={{ width: '100%', height: '100%' }}
                                src={photo.path}
                                alt="Site"></img>
                        </SwiperSlide>
                    )
                    )}
                </Swiper>
                <IonCardHeader>
                    <IonCardTitle>{site.name}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent style={{marginTop: 0}}>
                    <p>{site.properties.short_description}</p>

                    {reservationDetails.hasSearched ? (
                        <IonButton
                            onClick={() => { setReservationDetails({ ...reservationDetails, locationId: site.id, locationName: site.name }); setModalOpen(true) }}
                            shape="round"
                            expand="block"
                            color="dark"
                            style={{ marginTop: '20px' }}>
                            Book Now
                        </IonButton>
                    ) : (
                        <IonButton
                            onClick={() => { setModalOpen(true); console.log('Site:', site) }}
                            shape="round"
                            expand="block"
                            color="primary"
                            style={{ marginTop: '20px' }}>
                            View Details
                        </IonButton>
                    )}

                    <IonModal
                        isOpen={modalOpen}
                        presentingElement={parentPage!}
                        onDidDismiss={() => setModalOpen(false)}
                    >
                        <IonHeader>
                            <IonToolbar>
                                <IonButtons slot="start">
                                    <IonButton onClick={() => setModalOpen(false)}>
                                        <IonIcon icon={close} />
                                    </IonButton>
                                </IonButtons>
                                {reservationDetails.hasSearched ? (<IonTitle>Book your stay</IonTitle>) : <IonTitle>Location Details</IonTitle>}
                            </IonToolbar>
                        </IonHeader>
                        <IonContent>
                            <SiteDetail
                                site={site}
                                reservationDetails={reservationDetails}
                                closeModal={() => setModalOpen(false)}
                                setReservationDetails={setReservationDetails}
                                member={member}

                            />
                        </IonContent>

                    </IonModal>
                </IonCardContent>
            </IonCard>


        </>
    )
}

export default SiteCard;