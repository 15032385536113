const config =
  process.env.REACT_APP_ENV === 'production'
    ? {
        pk: 'pk_live_2dwyiUhp59WWayTgCbLR33Mh00KuwMn9Sc'
      }
    : {
        pk: 'pk_test_51Gr0SEEXWgycfo5az5TeMjyGpNH6Uf0FrpgYullyU7AJ397tGq2ibnjqo4Xo95a6LmZKJ22yb50ZjfbSCTz0xjze00nMYMhPAv'
      };


export default config;